import { useState } from "react";
import { prefix, sexs, foodType } from "../inc/DataDetail";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useArrYear, useCurrentYear } from "../inc/DateYear&Month";
import Swal from "sweetalert2";
import passwordSHA256 from "./passwordUtils";
import config from "../config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Template from "../component/Template";
import axios from "axios";

function Vetmember() {
  const navigate = useNavigate();
  const currentYear = useCurrentYear();
  const arrYear = useArrYear();
  const [startDate, setStartDate] = useState(new Date());
  const [showpwd, setShowPwd] = useState(false);
  const [pwdConfrim, setPwdConFirm] = useState("");
  const [pwd, setPwd] = useState("");
  const [data, setData] = useState({});
  const [accept, setAccept] = useState("Accept");

  const localtion = useLocation();
  const searchParams = new URLSearchParams(localtion.search);

  const textParams = searchParams.get("role");

  const handleCheckEmail = async () => {
    try {
      const payload = {
        email: data.email,
      };
      const res = await axios.post(
        config.apiPath + config.urlPathUser + "/checkemail",
        payload
      );

      if (res.data.success === true) {
        Swal.fire({
          icon: "success",
          text: "อีเมลนี้ยังไม่มีนี้ในระบบสามารถใช้สมัครสมาชิกได้",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "อีเมลนี้มีระบบไม่สามารถใช้สมัครสมาชิกได้",
        });
      }
    } catch (e) {
      Swal.fire({ icon: "error", text: e });
    }
  };

  const genCodeId = Math.floor(1000 + Math.random() * 9000);

  const handleSave = async (event) => {
    try {
      event.preventDefault();
      const hashedPassword = passwordSHA256(data.email, pwd, config.privateKey);
      if (pwd !== pwdConfrim) {
        Swal.fire({ icon: "error", text: "รหัสผ่านไม่ตรงกัน" });
      } else {
        const payload = {
          data: data,
          hdb: startDate.toLocaleString(),
          pwd: hashedPassword,
          pdpa: accept,
          role: textParams,
          codeId: "PGT-" + genCodeId,
        };

        const confirmRegi = await Swal.fire({
          icon: "question",
          title: "ยืนยันการสมัครสมาชิก หรือไม่",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Confirm",
        });

        if (confirmRegi.isConfirmed) {
          let timerInterval;
          Swal.fire({
            title: "ระบบกำลังส่งรหัสสมาชิกไปยังอีเมล!",
            html: "ภายในเวลาไม่เกิน <b></b> วินาที.",
            timer: 8000,
            timerProgressBar: true,
            showConfirmButton: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
              try {
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
                const res = await axios.post(
                  config.apiPath + config.urlPathUser + "/register",
                  payload
                );
                if (res.data.success === true) {
                  Swal.fire({
                    icon: "success",
                    title: "สมัครสมาชิกสำเร็จ",
                    html: `<h3>รหัสสมาชิกคือ: #PGT-${genCodeId}</h3>`,
                    allowEnterKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    confirmButtonText: "ตกลงรับทราบรหัสสมาชิก",
                  }).then(() => {
                    navigate("/vet/signin");
                  });
                }
              } catch (e) {
                if (e.response.data.code === "P2002") {
                  Swal.fire({
                    icon: "error",
                    title: "เลขบัตรประจำตัวประชาชนซ้ำ หรือ อีเมลซ้ำ",
                    text: "กรุณาติดต่อเจ้าหน้าที่ +66 5394 8114",
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "สมัครสมาชิกไม่สำเร็จ",
                    text: "กรุณาติดต่อเจ้าหน้าที่ +66 5394 8114",
                  });
                }
              }
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "สมัครสมาชิกไม่สำเร็จ",
        text: "กรุณาติดต่อเจ้าหน้าที่ +66 5394 8114",
      });
    }
  };

  return (
    <>
      <Template>
        <div className="header-title">
          <div className="header-text text-uppercase">
            PGT: REGISTRATION {textParams}
          </div>
          <Link to={"/registermember"} className="header-button-text">
            BACK
          </Link>
        </div>
        <form className="form-submit" onSubmit={handleSave}>
          <div className="vet-form-box-sign-in">
            <div className="vet-card">
              <div className="vet-title">
                <div>For Sign in to website</div>
              </div>
              <div className="row g-2 mt-2 ms-1">
                <div className="col-lg-8 col-md-8 col-12">
                  <label htmlFor="" className="form-label text-white">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="email"
                    required
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <label htmlFor="" className="form-label text-white text-end">
                    Check email
                  </label>
                  {data && data.email ? (
                    <>
                      <button
                        onClick={(e) => handleCheckEmail(data.email)}
                        type="button"
                        className="form-control bg-primary text-white"
                      >
                        {" "}
                        Click
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="form-control bg-primary text-white disabled"
                      >
                        {" "}
                        Click
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="row g-2 mt-2 ms-1">
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="" className="form-label text-white">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type={showpwd ? "text" : "password"}
                    className="form-control"
                    placeholder="รหัสอย่างน้อย 6 ตัวอักษร"
                    minLength={6}
                    required
                    onChange={(e) => setPwd(e.target.value)}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="" className="form-label text-white">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type={showpwd ? "text" : "password"}
                    className="form-control"
                    placeholder="รหัสอย่างน้อย 6 ตัวอักษร"
                    minLength={6}
                    required
                    onChange={(e) => setPwdConFirm(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className="form-check d-flex justify-content-start">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    id="showPassword"
                    onChange={(e) => setShowPwd(e.target.checked)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="flexCheckshowPasswordDefault"
                  >
                    Show password
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="card vet-regi-card">
            <div className="card-body">
              <div className="row g-2">
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="prefix" className="form-lable">
                    คำนำหน้าชื่อ <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    name="prefix"
                    required
                    onChange={(e) =>
                      setData({ ...data, prefix: e.target.value })
                    }
                  >
                    <option value="">เลือก..</option>
                    {prefix.map((item, index) => (
                      <option key={index} value={item.name_th}>
                        {item.name_th}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="admp" className="form-lable">
                    ตำแหน่งวิชาการ
                  </label>
                  <input
                    type="text"
                    name="admp"
                    className="form-control"
                    placeholder="ศ. ,รศ. ,ผศ."
                    onChange={(e) => setData({ ...data, admp: e.target.value })}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="fname_th" className="form-lable">
                    ชื่อ (ภาษาไทย) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="fname_th"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, fnameTh: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="lname_th" className="form-lable">
                    นามสกุล (ภาษาไทย) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname_th"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, lnameTh: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="fname_en" className="form-lable">
                    Firstname <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="fname_en"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, fnameEn: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="lname_en" className="form-lable">
                    Lastname <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname_en"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, lnameEn: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="nationality" className="form-lable">
                    สัญชาติ <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="nationality"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, nationality: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="ethnicity" className="form-lable">
                    เชื้อชาติ <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="ethnicity"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, ethnicity: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <label htmlFor="sexs" className="form-lable">
                    เพศ <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    name="sexs"
                    required
                    onChange={(e) => setData({ ...data, sex: e.target.value })}
                  >
                    <option value="">เลือก..</option>
                    {sexs.map((item, index) => (
                      <option key={index} value={item.sex}>
                        {item.sex}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <label htmlFor="hbd" className="form-lable">
                    Date of birth <span className="text-danger">*</span>
                  </label>
                  <br />
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="idCard" className="form-lable">
                    เลขบัตรประจำตัวประชาชน (ID No.)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="idCard"
                    className="form-control"
                    maxLength={13}
                    minLength={13}
                    required
                    onChange={(e) =>
                      setData({ ...data, idCard: e.target.value })
                    }
                  />
                </div>
                <div className=" col-12">
                  <label htmlFor="address" className="form-lable">
                    ที่อยู่ <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                  ></textarea>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="parish" className="form-lable">
                    ตำบล/แขวง <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="parish"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, parish: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="district" className="form-lable">
                    อำเภอ/เขต <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="district"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, district: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="county" className="form-lable">
                    จังหวัด <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="county"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, county: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="zipcode" className="form-lable">
                    รหัสไปรษณีย์ <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="zipcode"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, zipcode: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="cecode" className="form-lable">
                    เลขที่ใบอนุญาต <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="cecode"
                    className="form-control"
                    placeholder="01-12345/2566"
                    required
                    onChange={(e) =>
                      setData({ ...data, cecode: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="schoolEnd" className="form-lable">
                    จบการศึกษาจากมหาวิทยาลัย{" "}
                  </label>
                  <input
                    type="text"
                    name="schoolEnd"
                    className="form-control"
                    onChange={(e) =>
                      setData({ ...data, schoolEnd: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="schoolYear" className="form-lable">
                    ปีการศึกษา{" "}
                  </label>
                  <select
                    type="text"
                    name="schoolYear"
                    className="form-control"
                    onChange={(e) =>
                      setData({ ...data, schoolYear: e.target.value })
                    }
                  >
                    <option value={""}>{"เลือก.."}</option>
                    {arrYear.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="worklocaltion" className="form-lable">
                    ชื่อสถานประกอบการที่ทำงาน{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="worklocaltion"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, worklocaltion: e.target.value })
                    }
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="workaddress" className="form-lable">
                    ที่อยู่สถานประกอบการ <span className="text-danger">*</span>
                  </label>
                  <textarea
                    name="workaddress"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setData({ ...data, workaddress: e.target.value })
                    }
                  ></textarea>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="phone" className="form-lable">
                    เบอร์โทรศัพท์มือถือ <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                    required
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="line" className="form-lable">
                    Line ID{" "}
                  </label>
                  <input
                    type="text"
                    name="line"
                    className="form-control"
                    onChange={(e) =>
                      setData({ ...data, lineId: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="pfw" className="form-lable">
                    จังหวัดที่ทำงาน{" "}
                  </label>
                  <input
                    type="text"
                    name="pfw"
                    className="form-control"
                    onChange={(e) =>
                      setData({ ...data, workaddress: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="foodtype" className="form-lable">
                    ประเภทการกิน <span className="text-danger">*</span>
                  </label>
                  <select
                    name="foodtype"
                    className="form-select"
                    onChange={(e) =>
                      setData({ ...data, foodtype: e.target.value })
                    }
                    required
                  >
                    <option value="">เลือก..</option>
                    {foodType.length > 0
                      ? foodType.map((item) => (
                          <option value={item.food}>{item.food}</option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="certName" className="form-lable">
                    ชื่อสำหรับออกใบ certificate (ชื่อ - นามสกุล ภาษาอังกฤษ)
                  </label>
                  <input
                    type="text"
                    name="certName"
                    className="form-control"
                    onChange={(e) =>
                      setData({ ...data, certName: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="PDPA" className="form-lable">
                    ยอมรับ / ไม่ยอมรับ{" "}
                    <Link
                      to={"https://vmcmu.vet.cmu.ac.th/PDPA?lang=th"}
                      target="_black"
                    >
                      นโยบายความส่วนตัว{" "}
                    </Link>
                  </label>
                  <select
                    type="text"
                    name="PDPA"
                    className="form-select"
                    required
                    onChange={(e) => setAccept(e.target.value)}
                  >
                    <option value="Accept">ยอมรับ</option>
                    <option value="Don't accept">ไม่ยอมรับ</option>
                  </select>
                </div>
              </div>
            </div>
            {accept === "Accept" ? (
              <div className="d-flex justify-content-end vet-btn">
                <button type="submit" className="btn btn-primary">
                  Register
                </button>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-end vet-btn">
                  <Link to={"/"} type="button" className="btn btn-danger">
                    กลับหน้าหลัก
                  </Link>
                </div>
              </>
            )}
          </div>
        </form>
      </Template>
    </>
  );
}
export default Vetmember;
