import { useState, useEffect } from "react";
import useFetchDataInfo from "../../modules/FetchInfo";
import Template from "../../component/Template";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import Swal from "sweetalert2";
import * as dayJs from "dayjs";
import Modal from "../../component/Modal";
import jsQR from "jsqr";
import Navbar from "../../component/Navbar";

function CheckDataVet() {
  const { info, fetchDataInfo } = useFetchDataInfo();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const [count, setCount] = useState({});
  const [data, setData] = useState([]);
  const [uploadSlip, setUploadSlip] = useState({});
  const [swUploadQrcode, setSwUploadQrcode] = useState(false);
  const [swUploadnotQrcode, setSwUploadnotQrcode] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchCancel = async (datas) => {
    try {
      const currentDate = new Date();
      // Check each item in the datas array
      datas.forEach((item) => {
        if (!item.autodate_cancel) {
          return;
        }
        const cancelDate = new Date(item.autodate_cancel);
        if (currentDate >= cancelDate) {
          // Alert if the project is expired
          Swal.fire({
            icon: "warning",
            title: "หมดเวลาแนบสลิปเงินโอน",
            text: `${item.pgt_register_project.title}`,
            footer: `ระบบทำการยกเลิกโครการดังกล่าว`,
            timer: 5000,
          }).then(async () => {
            const res = await axios.delete(
              `${config.apiPath}${config.urlPathProject}/delect/prj/${item.userId}/${item.id}`,
              config.headers()
            );

            if (res.data.success === true) {
              window.location.reload();
            }
          });
        }
      });
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "ติดตต่อเจ้าหน้าที่",
        });
      }
    }
  };

  const fetchCount = async () => {
    try {
      const id = localStorage.getItem("codeId");
      const pending = await axios.get(
        config.apiPath + config.urlPathProject + "/count/" + id,
        config.headers()
      );

      if (pending.data.success === true) {
        setCount(pending.data.counts);
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "ติดตต่อเจ้าหน้าที่",
        });
      }
    }
  };

  const fetchData = async () => {
    if (localStorage.getItem("token_pgt")) {
      await fetchDataInfo();
      handleButtonClick(activeButton);
      fetchCount();
    } else {
      navigate("/");
    }
  };

  const handleButtonClick = async (button) => {
    try {
      setActiveButton(button);
      if (info && info.codeId) {
        const res = await axios.get(
          config.apiPath +
            config.urlPathProject +
            "/list/sent/transfer/" +
            info.codeId +
            "/" +
            button,
          config.headers()
        );

        if (res.data.success === true) {
          setData(res.data.results);
          fetchCancel(res.data.results);
        }
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "ติดตต่อเจ้าหน้าที่",
        });
      }
    }
  };
  const handleCancel = async (value, index) => {
    try {
      const conf = await Swal.fire({
        icon: "question",
        iconColor: "red",
        title: "ยืนยันยกเลิกโครงการ No." + index,
        text: "ใช่ หรือ ไม่",
        showConfirmButton: true,
        confirmButtonText: "ใช่",
        showCancelButton: true,
        cancelButtonText: "ไม่ใช่",
      });

      if (conf.isConfirmed) {
        const id = localStorage.getItem("codeId");
        const res = await axios.delete(
          `${config.apiPath}${config.urlPathProject}/delect/prj/${id}/${value}`,
          config.headers()
        );

        if (res.data.success === true) {
          Swal.fire({
            icon: "success",
            title: "ยกเลิกโครงการสำเร็จ",
          }).then(() => {
            window.location.reload();
          });
        }
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "ติดตต่อเจ้าหน้าที่",
        });
      }
    }
  };

  // ฟังก์ชันตรวจสอบ QR Code ในรูปภาพ
  const QRCODE = async (file) => {
    const img = new Image();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        img.src = reader.result;
      };

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = 800;
        canvas.height = 800;
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          resolve(`${code.data}`);
        } else {
          resolve(null);
        }
      };

      img.onerror = (error) => {
        reject("ไม่สามารถโหลดรูปภาพได้: " + error);
      };

      reader.readAsDataURL(file);
    });
  };

  const validateQRCode = (data) => {
    // ตรวจสอบว่า QR Code มีความยาวอย่างน้อย 35 ตัวอักษร
    if (data.length < 35) {
      return false;
    }
    // ตรวจสอบเฉพาะเลข 3 ตัวหน้าที่เป็นตัวเลข 000-999
    //  const bankCode = parseInt(data.substring(0, 3), 10);
    //  if (isNaN(bankCode) || bankCode < 0 || bankCode > 999) {
    //    return false;
    //  }

    // ตรวจสอบว่า 25 ตัวแรกเป็นตัวเลขทั้งหมด
    const first25Chars = data.substring(0, 25);
    if (!/^\d{25}$/.test(first25Chars)) {
      return false;
    }

    // ตรวจสอบว่า 10 ตัวท้ายมี "TH910" อยู่ในตำแหน่งสุดท้าย
    const last10Chars = data.substring(data.length - 10);
    if (!last10Chars.includes("TH910")) {
      return false;
    }

    return true;
  };

  const handleSaveSlip = async (event) => {
    try {
      event.preventDefault();

      if (uploadSlip.oldSlip && uploadSlip.fileSlip && uploadSlip.id) {
        if (uploadSlip.fileSlip.type.startsWith("image/")) {
          if (uploadSlip.fileSlip.size <= 2 * 1024 * 1024) {
            const qrCodeResult = await QRCODE(uploadSlip.fileSlip);
            const ConfrimSlip = await Swal.fire({
              icon: "question",
              title: "ยืนยันการแนบสลิปเงินโอนใหม่ No.1",
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: "ยืนยัน",
            });
            if (ConfrimSlip.isConfirmed) {
              if (qrCodeResult) {
                // ตรวจสอบว่า QR Code มีรูปแบบที่ถูกต้องหรือไม่
                const isValidQRCode = validateQRCode(qrCodeResult);
                // ดึง 10 ตัวสุดท้ายของ qrCodeResult
                const last35Chars = qrCodeResult.slice(-35);
                if (isValidQRCode) {
                  const formData = new FormData();
                  formData.append("myFile", uploadSlip.fileSlip);
                  formData.append("userId", uploadSlip.id);
                  formData.append("oldslip", uploadSlip.oldSlip);

                  const res = await axios.post(
                    config.apiPath +
                      config.urlPathProject +
                      "/upload/slip/user",
                    formData,
                    config.headers()
                  );

                  if (res.data.success === true) {
                    Swal.fire({
                      icon: "success",
                      text: `ข้อมูลถูกต้อง: ${last35Chars}`,
                    }).then(() => {
                      document.getElementById("btnModalClose").click();
                      window.location.reload();
                    });
                  }
                } else {
                  Swal.fire({
                    icon: "error",
                    text: "QR Code ที่พบไม่ถูกต้องหรือไม่ได้อัพโหลดรูปสลิปเงินโอน",
                  });
                }
              } else {
                Swal.fire({
                  icon: "error",
                  text: "กรุณาอัพโหลดสลิปเงินโอนเท่านั้น โดยในสลิปต้องมี QR Code ที่ตรวจสอบได้",
                });
              }
            }
          } else {
            Swal.fire({
              icon: "error",
              text: "ขนาดไฟล์เกิน 2 MB กรุณาเลือกไฟล์ที่ขนาดไม่เกิน 2 MB",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: "กรุณาอัปโหลดไฟล์รูปภาพเท่านั้น",
          });
        }
      } else {
        if (uploadSlip.fileSlip && uploadSlip.id) {
          if (uploadSlip.fileSlip.type.startsWith("image/")) {
            if (uploadSlip.fileSlip.size <= 2 * 1024 * 1024) {
              const qrCodeResult = await QRCODE(uploadSlip.fileSlip);
              const ConfrimSlip = await Swal.fire({
                icon: "question",
                title: "ยืนยันการอัพโหลดสลิปเงินโอน",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "ยืนยัน",
              });
              if (ConfrimSlip.isConfirmed) {
                if (qrCodeResult) {
                  // ตรวจสอบว่า QR Code มีรูปแบบที่ถูกต้องหรือไม่
                  const isValidQRCode = validateQRCode(qrCodeResult);
                  // ดึง 10 ตัวสุดท้ายของ qrCodeResult
                  const last35Chars = qrCodeResult.slice(-35);
                  if (isValidQRCode) {
                    const formData = new FormData();
                    formData.append("myFile", uploadSlip.fileSlip);
                    formData.append("userId", uploadSlip.id);

                    const res = await axios.post(
                      config.apiPath +
                        config.urlPathProject +
                        "/upload/slip/user",
                      formData,
                      config.headers()
                    );

                    if (res.data.success === true) {
                      Swal.fire({
                        icon: "success",
                        text: `ข้อมูลถูกต้อง: ${last35Chars}`,
                      }).then(() => {
                        document.getElementById("btnModalClose").click();
                        window.location.reload();
                      });
                    }
                  } else {
                    Swal.fire({
                      icon: "error",
                      text: "QR Code ที่พบไม่ถูกต้องหรือไม่ได้อัพโหลดรูปสลิปเงินโอน",
                    });
                  }
                } else {
                  Swal.fire({
                    icon: "error",
                    text: "กรุณาอัพโหลดสลิปเงินโอนเท่านั้น โดยในสลิปต้องมี QR Code ที่ตรวจสอบได้",
                  });
                }
              }
            } else {
              Swal.fire({
                icon: "error",
                text: "ขนาดไฟล์เกิน 2 MB กรุณาเลือกไฟล์ที่ขนาดไม่เกิน 2 MB",
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              text: "กรุณาอัปโหลดไฟล์รูปภาพเท่านั้น",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: "กรุณาแนบสลิปโอนเงิน",
          });
        }
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "ติดตต่อเจ้าหน้าที่",
          text: e.code + " " + e.response.data.statusCode,
        });
      }
    }
  };

  // const handleSaveSlipNotQrcode = async (event) => {
  //   try {
  //     event.preventDefault();

  //     console.log();
  //     if (uploadSlip.oldSlip && uploadSlip.fileSlip && uploadSlip.id) {
  //       if (uploadSlip.fileSlip.type.startsWith("image/")) {
  //         if (uploadSlip.fileSlip.size <= 2 * 1024 * 1024) {
  //           const ConfrimSlip = await Swal.fire({
  //             icon: "question",
  //             title: "ยืนยันการแนบสลิปเงินโอนใหม่",
  //             showCancelButton: true,
  //             showConfirmButton: true,
  //             confirmButtonText: "ยืนยัน",
  //           });
  //           if (ConfrimSlip.isConfirmed) {
  //             const formData = new FormData();
  //             formData.append("myFile", uploadSlip.fileSlip);
  //             formData.append("userId", uploadSlip.id);
  //             formData.append("oldslip", uploadSlip.oldSlip);

  //             const res = await axios.post(
  //               config.apiPath + config.urlPathProject + "/upload/slip/user",
  //               formData,
  //               config.headers()
  //             );

  //             if (res.data.success === true) {
  //               Swal.fire({
  //                 icon: "success",
  //                 text: `บันทึกข้อมูลสำเร็จ`,
  //               }).then(() => {
  //                 document.getElementById("btnModalClose").click();
  //                 window.location.reload();
  //               });
  //             }
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             text: "ขนาดไฟล์เกิน 2 MB กรุณาเลือกไฟล์ที่ขนาดไม่เกิน 2 MB",
  //           });
  //         }
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           text: "กรุณาอัปโหลดไฟล์รูปภาพเท่านั้น",
  //         });
  //       }
  //     } else {
  //       if (uploadSlip.fileSlip && uploadSlip.id) {
  //         if (uploadSlip.fileSlip.type.startsWith("image/")) {
  //           if (uploadSlip.fileSlip.size <= 2 * 1024 * 1024) {
  //             const ConfrimSlip = await Swal.fire({
  //               icon: "question",
  //               title: "ยืนยันการอัพโหลดสลิปเงินโอน",
  //               showCancelButton: true,
  //               showConfirmButton: true,
  //               confirmButtonText: "ยืนยัน",
  //             });
  //             if (ConfrimSlip.isConfirmed) {
  //               const formData = new FormData();
  //               formData.append("myFile", uploadSlip.fileSlip);
  //               formData.append("userId", uploadSlip.id);

  //               const res = await axios.post(
  //                 config.apiPath + config.urlPathProject + "/upload/slip/user",
  //                 formData,
  //                 config.headers()
  //               );

  //               if (res.data.success === true) {
  //                 Swal.fire({
  //                   icon: "success",
  //                   text: `บันทึกข้อมูลสำเร็จ`,
  //                 }).then(() => {
  //                   document.getElementById("btnModalClose").click();
  //                   window.location.reload();
  //                 });
  //               }
  //             }
  //           } else {
  //             Swal.fire({
  //               icon: "error",
  //               text: "ขนาดไฟล์เกิน 2 MB กรุณาเลือกไฟล์ที่ขนาดไม่เกิน 2 MB",
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             text: "กรุณาอัปโหลดไฟล์รูปภาพเท่านั้น",
  //           });
  //         }
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           text: "กรุณาแนบสลิปโอนเงิน",
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     if (
  //       e.response?.data?.error === "Unauthorized" &&
  //       e.response?.data?.statusCode === 401
  //     ) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Verify expired verification",
  //         text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
  //         timer: 2000,
  //       }).then(() => {
  //         localStorage.clear();
  //         window.location.reload();
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "ติดตต่อเจ้าหน้าที่",
  //         text: e.code + " " + e.response.data.statusCode,
  //       });
  //     }
  //   }
  // };

  const handleButtonSw = (value) => {
    if (value === "Qrcode") {
      setSwUploadQrcode(true);
      setSwUploadnotQrcode(false);
      setUploadSlip({
        fileSlip: null,
        id: uploadSlip.id,
      });
    } else if (value === "NotQrcode") {
      setSwUploadQrcode(false);
      setSwUploadnotQrcode(true);
      setUploadSlip({
        fileSlip: null,
        id: uploadSlip.id,
      });
    }
  };

  return (
    <>
      <Navbar />
      <main className="container mt-3">
        <div className="Genarate-qr-code mb-3">
          <Link
            to={`/genqrcode?userId=${btoa("header " + info.codeId)}&code=${btoa(
              "header " + info.email
            )}`}
            className="btn btn-light"
          >
            <i className="fa-solid fa-qrcode"></i>{" "}
            สร้างคิวอาร์โค้ดเพื่อใช่เข้าร่วมงาน
          </Link>
        </div>
        <div className="header-title bg-light">
          <div className="header-text text-uppercase ">
            ตรวจสอบข้อมูลลงทะเบียน
          </div>
          <div className="check-finance">
            <button
              className={`btn btn-outline-secondary position-relative w-100 ${
                activeButton === "pending"
                  ? "active"
                  : "" || count.pending === 0
                  ? "disabled"
                  : ""
              }`}
              onClick={() => handleButtonClick("pending")}
            >
              <span className="position-absolute  top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                {count && count.pending === 0 ? null : count.pending}
              </span>
              ชำระเงินค่าลงทะเบียน
            </button>
            <button
              className={`btn btn-outline-primary position-relative w-100 ${
                activeButton === "waiting"
                  ? "active"
                  : "" || count.waiting === 0
                  ? "disabled"
                  : ""
              }`}
              onClick={() => handleButtonClick("waiting")}
            >
              <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-primary">
                {count && count.waiting === 0 ? null : count.waiting}
              </span>
              รอตรวจสอบสลิปเงินโอน
            </button>
            <button
              className={`btn btn-outline-success position-relative w-100 ${
                activeButton === "approved"
                  ? "active"
                  : "" || count.approved === 0
                  ? "disabled"
                  : ""
              }`}
              onClick={() => handleButtonClick("approved")}
            >
              <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                {count && count.approved === 0 ? null : count.approved}
              </span>
              อนุมัติ
            </button>
            <button
              className={`btn btn-outline-danger position-relative w-100 ${
                activeButton === "fail"
                  ? "active"
                  : "" || count.fail === 0
                  ? "disabled"
                  : ""
              }`}
              onClick={() => handleButtonClick("fail")}
            >
              <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                {count && count.fail === 0 ? null : count.fail}
              </span>
              ไม่ผ่านอนุมัติ
            </button>
          </div>
        </div>

        {activeButton === "pending" &&
        data[0] &&
        data[0].sentTransferSlip &&
        data[0].sentTransferSlip === "pending" ? (
          <>
            {data !== undefined && data.length > 0
              ? data.map((item, index) => (
                  <>
                    <div className="card mt-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4 border">
                            โครงการ {index + 1}
                          </div>
                          <div className="col-8 border">
                            {item.pgt_register_project.title}
                          </div>

                          <div className="col-4 border">ราคา</div>
                          <div className="col-8 border">
                            {item.pgt_register_project.price_regi.toLocaleString(
                              "th-TH"
                            )}{" "}
                            THB
                          </div>

                          <div className="col-4 border">วันที่</div>
                          <div className="col-8 border">
                            {dayJs(item.createdAt).format(
                              config.dateFormatTime
                            )}
                          </div>

                          <div className="col-12 border btn btn-danger btn-sm w-100">
                            <button
                              onClick={(e) => handleCancel(item.id, index + 1)}
                              type="button"
                              className="btn btn-danger btn-sm w-100"
                            >
                              ยกเลิกโครงการ
                            </button>
                          </div>

                          <div className="col-12 border btn btn-success btn-sm w-100">
                            <button
                              onClick={(e) =>
                                setUploadSlip({ ...uploadSlip, id: item.id })
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#modalSlip"
                              className="btn btn-success btn-sm w-100"
                            >
                              ชำระเงินค่าลงทะเบียน
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}
          </>
        ) : null}

        {activeButton === "waiting" &&
        data[0] &&
        data[0].sentTransferSlip &&
        data[0].sentTransferSlip === "waiting" ? (
          <>
            {data !== undefined && data.length > 0
              ? data.map((item, index) => (
                  <>
                    <div className="card mt-3">
                      <div className="card-body">
                        <div className="row"></div>
                      </div>
                    </div>
                  </>
                ))
              : null}

            <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive mt-3">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <td style={{ width: 50 }}>No.</td>
                    <td style={{ textAlign: "start", width: 500 }}>โครงการ</td>

                    <td
                      className="text-primary"
                      style={{ textAlign: "center", width: 200 }}
                    >
                      วันที่
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {data !== undefined && data.length > 0
                    ? data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ fontSize: 14 }}>
                            {item.pgt_register_project.title}
                          </td>
                          <td style={{ fontSize: 16, textAlign: "center" }}>
                            {dayJs(item.W_updatedAt).format(
                              config.dateFormatTime
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {activeButton === "approved" &&
        data[0] &&
        data[0].sentTransferSlip &&
        data[0].sentTransferSlip === "approved" ? (
          <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive mt-3">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <td style={{ width: 100 }}>No.</td>
                  <td style={{ textAlign: "start", width: 500 }}>โครงการ</td>
                  <td
                    className="text-success"
                    style={{ textAlign: "center", width: 200 }}
                  >
                    วันที่
                  </td>
                </tr>
              </thead>
              <tbody>
                {data !== undefined && data.length > 0
                  ? data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ fontSize: 14 }}>
                          {item.pgt_register_project.title}
                        </td>
                        <td style={{ fontSize: 16, textAlign: "center" }}>
                          {dayJs(item.W_updatedAt).format(
                            config.dateFormatTime
                          )}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}

        {activeButton === "fail" &&
        data[0] &&
        data[0].sentTransferSlip &&
        data[0].sentTransferSlip === "fail" ? (
          <>
            {data !== undefined && data.length > 0
              ? data.map((item, index) => (
                  <>
                    <div className="card mt-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4 border">
                            โครงการ {index + 1}
                          </div>
                          <div className="col-8 border">
                            {item.pgt_register_project.title}
                          </div>

                          <div className="col-4 border text-danger">วันที่</div>
                          <div className="col-8 border">
                            {dayJs(item.F_updatedAt).format(
                              config.dateFormatTime
                            )}
                          </div>

                          <div className="col-4 border">หมายเหตุ</div>
                          <div className="col-8 border">{item.remaker}</div>

                          <div className="col-12 border btn btn-success btn-sm w-100">
                            <button
                              onClick={(e) =>
                                setUploadSlip({
                                  ...uploadSlip,
                                  id: item.id,
                                  oldSlip: item.transferSlip,
                                })
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#modalSlip"
                              className="btn btn-success btn-sm w-100"
                            >
                              แนบสลิปใหม่
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}
          </>
        ) : null}

        {activeButton === null ? (
          <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive mt-3">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <td className="text-center">เลือกข้อมูลที่ต้องการ</td>
                </tr>
              </thead>
            </table>
          </div>
        ) : null}
      </main>

      <Modal
        id="modalSlip"
        title={`แนบสลิปเงินโอน ID: ${uploadSlip.id}`}
        modalSize="modal-lg"
      >
        <div className="card box-number-bank">
          <div className="card-body">
            <div className="bank-text-title mb-2">บัญชีธนาคารไทยพาณิชย์</div>
            <div className="bank-text-sub">
              ชื่อบัญชี:{" "}
              <span className="bank-text">
                ศูนย์การเรียนรู้และส่งเสริมสุขภาพทางสัตวแพทย์ภาคเหนือ (NOVEL
                CMU)
              </span>
            </div>
            <div className="bank-text-sub">
              สาขา: <span className="bank-text">สาขามหาวิทยาลัยเชียงใหม่</span>
            </div>
            <div className="bank-text-sub">
              เลขที่บัญชี: <span className="bank-text">667 458463 6</span>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <button
              onClick={(e) => handleButtonSw("Qrcode")}
              className={`btn btn-primary w-100 ${
                swUploadQrcode ? "active bg-success" : ""
              }`}
            >
              แนบสลิปกรณีสมัครเอง
            </button>
          </div>
        </div>

        {swUploadQrcode ? (
          <>
            <form onSubmit={handleSaveSlip} className="was-validated">
              <label htmlFor="files" className=" form-label text-danger">
                อัพโหลดไฟล์ภาพขนาดไม่เกิน 2 mb
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) =>
                  setUploadSlip({ ...uploadSlip, fileSlip: e.target.files[0] })
                }
                required
              />

              <button type="submit" className="btn btn-primary mt-3">
                บันทึกสลิปเงินโอน
              </button>
            </form>
          </>
        ) : null}

        {swUploadnotQrcode ? (
          <>
            {/* <form onSubmit={handleSaveSlipNotQrcode} className="was-validated">
              <h2>บริษัทโอนให้</h2>
              <label htmlFor="files" className=" form-label text-danger">
                อัพโหลดไฟล์ภาพขนาดไม่เกิน 2 mb
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) =>
                  setUploadSlip({ ...uploadSlip, fileSlip: e.target.files[0] })
                }
                required
              />

              <button type="submit" className="btn btn-primary mt-3">
                บันทึกสลิปเงินโอน
              </button>
            </form> */}
          </>
        ) : null}
      </Modal>
    </>
  );
}

export default CheckDataVet;
