import { Link, useNavigate } from "react-router-dom";
import logo from "./../assets/images/Postgraduate.svg";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import encBase64 from "crypto-js/enc-base64";

function SignInStaff() {
  const [tagRegi, setTagRegi] = useState(0);
  const [agency, setAgency] = useState([]);
  const [code, setCode] = useState({});
  const [genCode, setGenCode] = useState("");
  const [regi, setRegi] = useState({});
  const [statusCode, setStatusCode] = useState(0);
  const [checker, setChecker] = useState(false);
  const [checker2, setChecker2] = useState(false);
  const [login, setlogin] = useState({})

  const navigrate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathStaff + "/list/agency"
      );
      if (res.data.success === true) {
        setAgency(res.data.data);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch data",
      });
    }
  };

  const passwordSHA256 = () => {
    const hashDigest = sha256(
      regi.email + regi.pwd || login.email + login.pwd
    ).toString();
    const eBase64 = encBase64.stringify(
      hmacSHA512(hashDigest, config.privateKey)
    );
    return eBase64;
  };

  const handleCode = async (event) => {
    try {
      event.preventDefault();
      const payload = {
        name: code.name,
        agencyId: code.agencyId,
      };

      const res = await axios.post(
        config.apiPath + config.urlPathStaff + "/genarate/code",
        payload
      );

      if (res.data.success === true) {
        setGenCode(res.data.gen.access_token);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to GenarateCoed",
      });
    }
  };

  const handleCopy = () => {
    try {
      const tempTextarea = document.createElement("textarea");
      tempTextarea.value = genCode;
      document.body.appendChild(tempTextarea);
      tempTextarea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextarea);
      Swal.fire({
        icon: "success",
        title: "Copied!",
        text: "The code has been copied to the clipboard.",
        timer: 2000, // ปิด Swal หลังจาก 2 วินาที
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setCode({
        name: "",
        agencyId: "",
      });
      setTagRegi(0);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while copying:",
      });
    }
  };

  const handleCheckCode = async (code) => {
    try {
      const genCode = {
        gencode: code,
      };

      const res = await axios.post(
        config.apiPath + config.urlPathStaff + "/genarate/checkcode",
        genCode
      );
      if (res.data.success === true) {
        setStatusCode(1);
        setRegi({ ...regi, cardId: code });
      } else if (res.data.respCode === 4083) {
        setStatusCode(2);
      } else if (res.data.respCode === 4004) {
        setStatusCode(3);
      }
    } catch (e) {
      setStatusCode(4);
    }
  };

  const handleSave = async (event) => {
    try {
      event.preventDefault();
      if (statusCode === 1) {
        if (regi.pwd !== regi.pwdConfirm) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "รหัสผ่านไม่ตรงกัน ",
          });
        } else if (regi.pwd.length < 6) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร",
          });
        } else {
          const payload = {
            codeId: regi.cardId,
            email: regi.email + "@cmu.ac.th",
            fname: regi.fname,
            pwd: passwordSHA256(regi.pwd),
          };

          const res = await axios.post(
            config.apiPath + config.urlPathStaff + "/create/staff",
            payload
          );

          if (res.data.success === true) {
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              text: "ท่านสามารถเข้าสู่ระบบด้วย Email และ Password ของท่าน",
            }).then(() => {
              setRegi({});
              setTagRegi(0);
              setStatusCode(0);
            });
          }
        }
      } else if (statusCode === 2) {
        console.log("fail code");
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error Create Staff ",
      }).then(() => {
        setRegi({});
        setTagRegi(0);
        setStatusCode(0);
      });
    }
  };

  const handleSignin = async (event) => {
    try {
      event.preventDefault();
      const payload = {
        email: login.email + "@cmu.ac.th",
        pwd: passwordSHA256(login.pwd)
      };
      const res = await axios.post(
        config.apiPath + config.urlPathStaff + "/login",
        payload
      );

      if (res.data.success === true) {
        Swal.fire({
            icon: 'success',
            text: 'Success sign in',
            timer: 2000,
            timerProgressBar: true
        }).then(()=> {
          const token = res.data.token.access_token;
          localStorage.setItem("token_pgt_staff", token);
          navigrate("/dashboard/staff");
        })
      } else if (res.data.respCode === 400) {
         Swal.fire({
           icon: "error",
           title: "Error",
           text: `Email or password invalid`,
         })
      } 

      
    } catch (e) {
       Swal.fire({
         icon: "error",
         title: "Error",
         text:` Error System Login ${e}`
       }).then(() => {
        
       });
    }
  }

  return (
    <>
      <div className="container py-3">
        <div className="d-flex align-items-center ">
          <img src={logo} className="img-logo" alt="" />
          <div className="d-block ms-2">
            <span className="h1">Postgraduate Education Center</span>
            <br />
            <span className="h6 ms-1">
              Faculty of Veterinary Medicine Chiang Mai University
            </span>
          </div>
        </div>
      </div>
      <div className="border-top-navbar">
        <div className="container d-flex justify-content-between">
          <ul className="header-navbar">
            <li className="header-nav-item active">Facebook Page</li>
          </ul>
          <ul className="header-navbar">
            <li className="header-nav-item active">
              สำหรับเจ้าหน้าที่เท่านั้น
            </li>
          </ul>
        </div>
        <div className="text-center"></div>
      </div>

      <div className="container container-box py-3">
        <div className="card card-staff-box">
          <div className="card-body">
            {tagRegi === 0 ? (
              <>
                <form onSubmit={handleSignin} className="card-staff-form">
                  <label htmlFor="" className="form-label mb-3">
                    Postgraduate Education Center System
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={e => setlogin({...login, email: e.target.value})}
                    />
                    <label
                      htmlFor=""
                      className="input-group-text"
                      style={{ width: 150 }}
                    >
                      @cmu.ac.th
                    </label>
                  </div>
                  <div className="input-group">
                    <input
                      type={checker2 ? "text" : "password"}
                      className="form-control"
                      placeholder="Password"
                      required
                      minLength={6}
                      onChange={e => setlogin({...login, pwd: e.target.value})}
                    />
                    <span className="input-group-text">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="showpassword"
                          onChange={(e) => setChecker2(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="showpassword"
                        >
                          Show password
                        </label>
                      </div>
                    </span>
                  </div>
                  <button type="submit" className="btn btn-primary mt-3 w-100">
                    <i className="fa fa-key me-2"></i>
                    Sign In
                  </button>
                </form>
              </>
            ) : tagRegi === 1 ? (
              <>
                {" "}
                <form onSubmit={handleSave} className="card-staff-form">
                  <label htmlFor="" className="form-label mb-3">
                    Register System
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full name"
                      required
                      onChange={(e) =>
                        setRegi({ ...regi, fname: e.target.value })
                      }
                    />
                  </div>
                  {statusCode === 0 && (
                    <div
                      className="alert alert-primary"
                      style={{ fontWeight: 500 }}
                    >
                      กรอกรหัส{" "}
                      <span className="text-danger">
                        (รหัสต้องได้รับการอนุมัติก่อนถึงจะใช้ได้)
                      </span>
                    </div>
                  )}
                  {statusCode === 1 && (
                    <div
                      className="alert alert-success"
                      style={{ fontWeight: 500 }}
                    >
                      รหัสนี้ได้รับการอนุมัติ
                    </div>
                  )}
                  {statusCode === 2 && (
                    <div
                      className="alert alert-danger"
                      style={{ fontWeight: 500 }}
                    >
                      รหัสนี้ยังไม่ได้รับการอนุมัติ
                    </div>
                  )}
                  {statusCode === 3 && (
                    <div
                      className="alert alert-danger"
                      style={{ fontWeight: 500 }}
                    >
                      รหัสนี้ถูกใช้งานแล้ว กรุณาขอรหัสใหม่ หรือ
                      ติดต่อเจ้าหน้าที่
                    </div>
                  )}
                  {statusCode === 4 && (
                    <div
                      className="alert alert-danger"
                      style={{ fontWeight: 500 }}
                    >
                      รหัสไม่ถูกต้อง กรุณาติดตต่อเจ้าหน้าที่
                    </div>
                  )}

                  <div className="input-group mb-3">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Code (หากไม่มีให้กดขอรหัส เพื่อป้องกัน BOT)"
                      required
                      rows={4}
                      onBlur={(e) => handleCheckCode(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={(e) =>
                        setRegi({ ...regi, email: e.target.value })
                      }
                    />
                    <label
                      htmlFor=""
                      className="input-group-text"
                      style={{ width: 150 }}
                    >
                      @cmu.ac.th
                    </label>
                  </div>
                  <div className="input-group">
                    <input
                      type={checker ? "text" : "password"}
                      className="form-control"
                      placeholder="Password"
                      required
                      minLength={6}
                      onChange={(e) =>
                        setRegi({ ...regi, pwd: e.target.value })
                      }
                    />
                    <input
                      type={checker ? "text" : "password"}
                      className="form-control"
                      placeholder="Confirm Password"
                      required
                      minLength={6}
                      onChange={(e) =>
                        setRegi({ ...regi, pwdConfirm: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-check mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) => setChecker(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Show password
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary mt-3 w-100">
                    Sign up
                  </button>
                </form>
              </>
            ) : tagRegi === 2 ? (
              <>
                <form onSubmit={handleCode} className="card-staff-form">
                  <label htmlFor="" className="form-label mb-3">
                    Code System
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ชื่อผู้ขอ"
                      required
                      value={code.name}
                      onChange={(e) =>
                        setCode({ ...code, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="input-group">
                    <select
                      className="form-select"
                      required
                      value={code.agencyId}
                      onChange={(e) =>
                        setCode({ ...code, agencyId: e.target.value })
                      }
                    >
                      <option value="">Select..</option>
                      {agency.length > 0
                        ? agency.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.agency}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  {genCode ? (
                    <>
                      <textarea
                        className="form-control mt-3"
                        value={genCode}
                        cols={10}
                        disabled
                        readOnly
                      />
                      <button
                        type="button"
                        className="btn btn-primary w-100 mt-2"
                        onClick={handleCopy}
                      >
                        Copy
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary mt-3 w-100"
                      >
                        <i className="fa fa-key me-2"></i>
                        Send
                      </button>
                    </>
                  )}
                </form>
              </>
            ) : (
              <></>
            )}

            <hr />
            <div className="row g-2">
              {tagRegi === 0 ? (
                <>
                  <div className="col-lg-4 col-12">
                    <Link
                      onClick={(e) => setTagRegi(1)}
                      className="btn btn-outline-primary w-100"
                    >
                      <i className="fa fa-lock-open"></i> สมัครสมาชิก
                    </Link>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Link to={'/'} className="btn btn-outline-primary w-100">
                      <i className="fa fa-home"></i> Home
                    </Link>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Link
                      onClick={(e) => setTagRegi(2)}
                      className="btn btn-outline-primary w-100"
                    >
                      <i className="fa fa-key"></i> ขอรหัส
                    </Link>
                  </div>
                </>
              ) : tagRegi === 1 ? (
                <>
                  <div className="col-lg-4 col-12">
                    <Link
                      onClick={(e) => setTagRegi(0)}
                      className="btn btn-outline-danger w-100"
                    >
                      <i className="fa fa-lock"></i> ปิดสมัคร
                    </Link>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Link to={'/'} className="btn btn-outline-primary w-100">
                      <i className="fa fa-home"></i> Home
                    </Link>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Link className="btn btn-outline-primary disabled w-100">
                      <i className="fa fa-key"></i> ขอรหัส
                    </Link>
                  </div>
                </>
              ) : tagRegi === 2 ? (
                <>
                  <div className="col-lg-4 col-12">
                    <Link className="btn btn-outline-primary w-100 disabled">
                      <i className="fa fa-lock-open"></i> สมัครสมาชิก
                    </Link>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Link to={'/'} className="btn btn-outline-primary w-100">
                      <i className="fa fa-home"></i> Home
                    </Link>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Link
                      onClick={(e) => setTagRegi(0)}
                      className="btn btn-outline-danger w-100 closeBtnCode"
                    >
                      <i className="fa fa-key"></i> ปิดขอรหัส
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="card card-footer-custom">
          <div className="card-footer-layout">
            <div className="card-body">
              <div className="title">ระบบบริหารจัดการข้อมูลของ</div>
              <div className="subtitle">Postgraduate Education Center</div>
              <br />
              <div className="email">
                <div style={{ fontWeight: 500 }}>
                  ติดปัญหาในการใช้งานกรุณาติดต่อ
                </div>
              </div>
              <div className="email">
                <span>Phone:</span>{" "}
                <Link to={"tel:+66 5398 4114"}>+66 5398 4114</Link> (ภพ)
              </div>
            </div>
            <div className="card-body text-end ">
              <div className="footer-staff">
                <Link to={"/signinstaff"} className="staff-signin">
                  <i className="fa fa-book me-1"></i>
                  คู่มือการใช้งาน
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignInStaff;
