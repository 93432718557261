import TemplateAdmin from "../component/TemplateAdmin";
import { useEffect, useState, useMemo } from "react";
import { Swalerror } from "../modules/SwalError";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import config from "../../config";
import Swal from "sweetalert2";

const ListSponsor = () => {
  const [dataTable, setDataTable] = useState([]);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  const fetchDataUser = async () => {
    try {
      const res = await axios.get(
        `${config.apiPath}/api/pgt/sponsor/list/data/projects`,
        config.headers_staff()
      );
      if (res.data.success === true) {
        setDataTable(res.data.result);
      } else if (res.data.respCode === 209) {
        Swal.fire({
          icon: "info",
          title: "ไม่มีข้อมูล",
        });
      }
    } catch (e) {
      //   Swalerror(e, navigate);
    }
  };

  useEffect(() => {
    fetchDataUser();
  }, []);

  const handleDeleteSponsor = async (id) => {
    try {
      const res = await axios.delete(
        `${config.apiPath}/api/pgt/sponsor/delete/project/${id}`,
        config.headers_staff()
      );
      if (res.data.success === true) {
        Swal.fire({
          icon: "success",
          title: "ลบข้อมูลสำเร็จ",
        });
        fetchDataUser();
      }
    } catch (e) {
      //   Swalerror(e, navigate);
    }
  };

  const data = dataTable
    ? dataTable.filter((item) =>
        Object.values(item).some((val) => {
          return (
            (typeof val === "string" &&
              val.toLowerCase().includes(searchText.toLowerCase())) ||
            (typeof val === "number" && val === parseInt(searchText))
          );
        })
      )
    : [];

  const columns = [
    {
      name: "รหัสสมาชิก",
      selector: (row) => row.pgt_user.codeId,
      width: "150px",
    },
    {
      name: "ชื่อ",
      selector: (row) => row.pgt_user.fnameTh + " " + row.pgt_user.lnameTh,
      width: "250px",
    },
    {
      name: "ลงทะเบียนโครงการ",
      cell: (row) => {
        return <>{row.pgt_register_project.title}</>;
      },
      width: "600px",
    },
    {
      name: "ลบข้อมูล",
      cell: (row) => {
        return (
          <>
            <button
              onClick={(e) => handleDeleteSponsor(row.id)}
              className="btn btn-danger"
            >
              <i className="fa fa-times"></i>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <TemplateAdmin>
        <div className="row g-2 mt-2">
          <div className="col-lg-6 me-auto">
            <h4>รายชื่อที่สปอนเซอร์ที่เพิ่มโครงการ</h4>
          </div>
          <div className="col-lg-6 ms-auto">
            <div className="input-group">
              <label htmlFor="search" className="input-group-text">
                <i className="fa fa-search fa-1x me-2"></i>
              </label>
              <input
                type="text"
                name="search"
                className="form-control"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <DataTable
              columns={columns}
              data={data}
              // title={"ค้นหาข้อมูล"}
              // actions={actionsMemo}
              pagination
              paginationPerPage={10}
            />
          </div>
        </div>
      </TemplateAdmin>
    </>
  );
};
export default ListSponsor;
