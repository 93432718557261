import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TemplateAdmin from "../component/TemplateAdmin";
import DataTable from "react-data-table-component";
import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";

import axios from "axios";
import config from "../../config";
import * as dayJs from "dayjs";
import Modal from "../../component/Modal";

const DashboardStaff = () => {
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [select, setSelect] = useState("");
  const [rowModal, setRowModal] = useState({});
  const [row, setRow] = useState({});
  const [remark, setRemark] = useState("");

  const localtion = useLocation();
  const [searchParams] = useSearchParams(localtion.search);

  const getUrl = atob(searchParams.get("agency"));
  const result = getUrl.replace("heander", "").trim().split(" ").pop();

  const columns = [
    {
      name: "Send&No.",
      width: "150px",
      cell: (row, index) => {
        return (
          <>
            {row && row.sentTransferSlip === "approved" ? (
              <>
                <div className="d-flex justify-content-between">
                  {row &&
                  row.pgt_user &&
                  row.pgt_user.PGT_Send_Receipt.length > 0 ? (
                    <div className="bg-success px-1 rounded-circle me-2">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  ) : (
                    <div className="bg-danger px-1 rounded-circle me-2">
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                  )}
                </div>
                {index + 1}
              </>
            ) : (
              <>{index + 1}</>
            )}
          </>
        );
      },
    },

    {
      name: "รหัสสมาชิก",
      selector: (row) => row.userId,
      width: "150px",
    },
    {
      name: "ชื่อโครงการ",
      selector: (row) =>
        row.pgt_register_project.title.replace(
          "The VMCMU Postgraduate Training Program: ",
          ""
        ),
      width: "500px",
    },
    {
      name: "วันที่",
      cell: (row) => {
        return (
          <>
            {row.sentTransferSlip === "waiting" ? (
              <div className="">
                {dayJs(row.W_updatedAt).format(config.dateFormatTime)}
              </div>
            ) : null}

            {row.sentTransferSlip === "approved" ? (
              <>
                <div className="">
                  {dayJs(row.S_updatedAt).format(config.dateFormatTime)}
                </div>
              </>
            ) : null}

            {row.sentTransferSlip === "fail" ? (
              <div className="">
                {dayJs(row.F_updatedAt).format(config.dateFormatTime)}
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      cell: (row) => {
        return (
          <>
            {row && row.sentTransferSlip === "waiting" ? (
              <button
                onClick={(e) => setRowModal(row)}
                data-bs-toggle="modal"
                data-bs-target="#waitingModal"
                className="btn btn-primary"
              >
                ตรวจสอบ
              </button>
            ) : null}

            {row && row.sentTransferSlip === "approved" ? (
              <>
                <button
                  onClick={(e) => handleCancelApproved(row)}
                  className="btn btn-danger"
                >
                  ยกเลิก
                </button>
                <button
                  onClick={(e) => handleShowModal(row)}
                  data-bs-toggle="modal"
                  data-bs-target="#sendReceipt"
                  className="btn btn-primary ms-2"
                >
                  ดูข้อมูล
                </button>
              </>
            ) : null}

            {row && row.sentTransferSlip === "fail" ? (
              <button
                onClick={(e) => handleCancelApproved(row)}
                className="btn btn-danger"
              >
                ยกเลิกอนุมัติ
              </button>
            ) : null}
          </>
        );
      },
    },
  ];

  const data = dataTable;

  useEffect(() => {
    fetchDataSearchParams();
  }, [searchParams]);

  const Swalerror = async (e) => {
    if (
      e.response?.data?.error === "Unauthorized" &&
      e.response?.data?.statusCode === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Verify expired verification",
        text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
        timer: 2000,
      }).then(() => {
        localStorage.clear();
        navigate("/");
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "ติดตต่อเจ้าหน้าที่",
        text: e.code + " " + e.response.data.statusCode,
      }).then(() => {
        navigate("/");
      });
    }
  };

  const fetchDataSearchParams = async () => {
    if (searchParams.size) {
      const getUrl = atob(searchParams.get("agency"));
      const result = getUrl.replace("heander", "").trim().split(" ").pop();

      try {
        const res = await axios.get(
          `${config.apiPath + config.urlPathProject}/list/status/${result}`,
          config.headers_staff()
        );

        if (res.data.success) {
          setDataTable(res.data.results);
          fecthDataProject();
        }
      } catch (e) {
        Swalerror(e);
      }
    }
  };

  const fecthDataProject = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/select/list/project",
        config.headers_staff()
      );

      if (res.data.success === true) {
        setSelect(res.data.results);
      }
    } catch (e) {
      Swalerror(e);
    }
  };

  const handleChangeProject = async (id) => {
    try {
      const result = getUrl.replace("heander", "").trim().split(" ").pop();
      const res = await axios.get(
        `${
          config.apiPath + config.urlPathProject
        }/list/titleId/${result}/${id}`,
        config.headers_staff()
      );

      if (res.data.success) {
        setDataTable(res.data.results);
        fecthDataProject();
      } else {
        setDataTable([]);
        throw res.data.message;
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: e,
      });
    }
  };

  const handleApproved = async () => {
    try {
      const confrimapp = await Swal.fire({
        icon: "question",
        title: "ยืนยันอนุมัติ",
        html: `<h3>รหัสสมาชิก: ${rowModal.userId}</h3>`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "อนุมัติ",
        cancelButtonText: "ยกเลิก",
      });

      if (confrimapp.isConfirmed) {
        let timerInterval;
        const payload = {
          titleId: rowModal.titleId,
          userId: rowModal.userId,
          sentTransferSlip: "approved",
          newdate: new Date().toLocaleString(),
        };

        Swal.fire({
          title: `ระบบกำลังส่งอีเมลตอบกลับให้กับ! ${rowModal.userId}`,
          html: "ภายในเวลาไม่เกิน <b></b> วินาที.",
          timer: 8000,
          timerProgressBar: true,
          showConfirmButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: async () => {
            try {
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);

              const res = await axios.put(
                config.apiPath +
                  config.urlPathProject +
                  "/update/status/transfer",
                payload,
                config.headers_staff()
              );
              if (res.data.success === true) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  showConfirmButton: false,
                  timer: 1000,
                }).then(() => {
                  document.getElementById("btnModalClose").click();
                  fetchDataSearchParams();
                  window.location.reload();
                });
              }
            } catch (e) {
              Swalerror(e);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      }
    } catch (e) {
      Swalerror(e);
    }
  };

  const handleNoApproved = async () => {
    try {
      if (remark) {
        const confrimapp = await Swal.fire({
          icon: "question",
          iconColor: "red",
          title: "ยืนยันไม่อนุมัติให้กับ",
          html: `<h3>รหัสสมาชิก: ${rowModal.userId}</h3>`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "ไม่อนุมัติ",
          confirmButtonColor: "red",
          cancelButtonText: "ยกเลิก",
        });

        if (confrimapp.isConfirmed) {
          let timerInterval;
          const payload = {
            titleId: rowModal.titleId,
            userId: rowModal.userId,
            remark: remark,
            sentTransferSlip: "fail",
            newdate: new Date().toLocaleString(),
          };

          Swal.fire({
            title: `ระบบกำลังส่งอีเมลตอบกลับให้กับ! ${rowModal.userId}`,
            html: "ภายในเวลาไม่เกิน <b></b> วินาที.",
            timer: 8000,
            timerProgressBar: true,
            showConfirmButton: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
              try {
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);

                const res = await axios.put(
                  config.apiPath +
                    config.urlPathProject +
                    "/update/status/transfer",
                  payload,
                  config.headers_staff()
                );
                if (res.data.success === true) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    showConfirmButton: false,
                    timer: 1000,
                  }).then(() => {
                    document.getElementById("btnModalClose").click();
                    fetchDataSearchParams();
                    window.location.reload();
                  });
                }
              } catch (e) {
                Swalerror(e);
              }
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          text: "กรุณาระบุที่หมายเหตุด้วย",
        });
      }
    } catch (e) {
      Swalerror(e);
    }
  };

  const handleCancelApproved = async (row) => {
    try {
      const confrimapp = await Swal.fire({
        icon: "question",
        title: "ยืนยันยกเลิกอนุมัติ",
        html: `<h3>รหัสสมาชิก: ${row.userId}</h3>`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "ตกลงยกเลิก",
        cancelButtonText: "ไม่ยกเลิก",
      });

      if (confrimapp.isConfirmed) {
        const payload = {
          titleId: row.titleId,
          userId: row.userId,
          sentTransferSlip: "waiting",
        };

        const res = await axios.put(
          config.apiPath + config.urlPathProject + "/update/status/transfer",
          payload,
          config.headers_staff()
        );
        if (res.data.success === true) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          }).then(() => {
            document.getElementById("btnModalClose").click();
            fetchDataSearchParams();
            window.location.reload();
          });
        }
      }
    } catch (e) {
      Swalerror(e);
    }
  };

  const handleSendReceipt = async (userId, titleId) => {
    try {
      const confirmReceipt = await Swal.fire({
        icon: "question",
        title: "ยืนยันการเปลี่ยนสถานะออกใบเสร็จ",
        html: `<h3>รหัสสมาชิก: ${userId}</h3>`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      });

      if (confirmReceipt.isConfirmed) {
        const payload = {
          userId: userId,
          titleId: titleId,
        };
        const res = await axios.post(
          config.apiPath + config.urlPathProject + "/add/send/receipt",
          payload,
          config.headers_staff()
        );
        if (res.data.success === true) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          }).then(() => {
            const btn = document.getElementsByClassName("btnClose");
            for (let i = 0; i < btn.length; i++) btn[i].click();
            fetchDataSearchParams();
          });
        }
        if (res.data.respCode === 409) {
          Swal.fire({
            icon: "error",
            title: "ส่งข้อมูลซ้ำ",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    } catch (e) {
      Swalerror(e);
    }
  };

  const textTitle = (
    <div className="d-flex justify-content-between mt-3">
      <div>ตารางข้อมูล {result}</div>
    </div>
  );

  const downloadCSV = (data) => {
    const csvData = data.map((row) => ({
      // กำหนดคอลัมน์ของ CSV ที่ต้องการ
      // เช่น ถ้า row มี id, name และ age:
      รหัสสมาชิก: row.userId,
      รหัสโครงการ: row.titleId,
      ชื่อนามสกุล: `${row.pgt_user.prefix} ${row.pgt_user.fnameTh} ${row.pgt_user.lnameTh}`,
      เลขประจำตัวผู้เสียภาษี: row.pgt_user.idCard,
      สถานะ: row.pgt_user.role,
      email: row.pgt_user.email,
      ที่อยู่: `${row.pgt_user.address} ต.${row.pgt_user.parish} อ.${row.pgt_user.district} จ.${row.pgt_user.county} ${row.pgt_user.zipcode} (Tel: ${row.pgt_user.phone})`,
      ทำการออกใบเสร็จ:
        row.pgt_user.PGT_Send_Receipt &&
        row.pgt_user.PGT_Send_Receipt.length > 0
          ? "ส่งเรียบร้อย"
          : "ยังไม่ได้ส่ง",
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(","), // แถวหัวข้อ
      ...csvData.map((row) => Object.values(row).join(",")), // แถวข้อมูล
    ].join("\n");

    // เพิ่ม BOM เพื่อให้แน่ใจว่าไฟล์จะถูกอ่านเป็น UTF-8
    const bom = "\uFEFF";
    const blob = new Blob([bom + csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${dayJs(new Date()).format(config.dateFormat)}.csv`;
    link.click();
  };

  const actionsMemo = useMemo(
    () => (
      <button className="btn btn-secondary" onClick={() => downloadCSV(data)}>
        {" "}
        <i className="fa fa-file"></i> Export
      </button>
    ),
    [data]
  );

  const handleShowModal = (row) => {
    setRow({}); // ล้างค่าก่อนหน้า
    setTimeout(() => setRow(row), 0);
  };


  return (
    <>
      <TemplateAdmin>
        {searchParams.size ? (
          <>
            <select
              onChange={(e) => handleChangeProject(e.target.value)}
              className="form-select"
            >
              <option value="">เลือกโครงการที่ต้องการค้นหา</option>
              {select.length > 0
                ? select.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.title.replace(
                        "The VMCMU Postgraduate Training Program:",
                        ""
                      )}
                    </option>
                  ))
                : null}
            </select>

            <div className="py-2"></div>

            {data.length > 0 ? (
              <DataTable
                columns={columns}
                data={data}
                title={textTitle}
                actions={actionsMemo}
                pagination
                paginationPerPage={10}
              />
            ) : (
              <div style={{ padding: "10px", textAlign: "center" }}>
                ไม่มีข้อมูล
              </div>
            )}
          </>
        ) : (
          <>
            <div className="container">
              <div className="text-muted">
                <i className="fa fa-note-sticky"></i> กราฟ ต่างๆ
              </div>
              <hr />
              <div className="row g-2">
                <div className="col-lg-4 col-md-6">
                  <div className="card">
                    <div className="card-body text-center">
                      อยู่ระหว่าการพัฒนา
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </TemplateAdmin>

      <Modal
        id="waitingModal"
        title={"รหัสสมาชิก: " + " " + rowModal.userId}
        modalSize="modal-xl"
      >
        <div className="modal-title">
          {rowModal &&
          rowModal.pgt_register_project &&
          rowModal.pgt_register_project.title
            ? rowModal.pgt_register_project.title.replace(
                "The VMCMU Postgraduate Training Program:",
                ""
              )
            : null}
        </div>

        <div className="row">
          <div className="col-7 modal-fin-data">
            <div className="modal-box-name">
              <i className="fa fa-user"></i>{" "}
              {rowModal.pgt_user
                ? `${rowModal.pgt_user.prefix} ${rowModal.pgt_user.fnameTh} ${rowModal.pgt_user.lnameTh} (${rowModal.pgt_user.role})`
                : "User data not available"}
            </div>

            <div className="modal-box-remark">
              <label htmlFor="" className="text-danger">
                <u>หมายเหตุ</u> (กรณีไม่อนุมัติ)
              </label>
              <textarea
                onChange={(e) => setRemark(e.target.value)}
                rows={10}
                className="form-control"
              ></textarea>
            </div>
            <div className="modal-box-button">
              <div className="modal-flex-1">
                <button onClick={handleNoApproved} className="btn btn-danger">
                  ไม่อนุมัติ
                </button>
                <button onClick={handleApproved} className="btn btn-success">
                  อนุมัติ
                </button>
              </div>
              <div className="modal-flex-2">
                {rowModal.pgt_register_project ? (
                  <>
                    <button className="btn btn-info">
                      ค่าลงทะเบียน{" "}
                      {rowModal.pgt_register_project.price_regi.toLocaleString(
                        "th-TH"
                      )}{" "}
                      บาท
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-5">
            <img
              src={config.apiPath + "/upload/PGT/Slip/" + rowModal.transferSlip}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </Modal>

      <Modal
        id="sendReceipt"
        title={`รหัสสมาชิก: ${row.userId} / ${
          row.pgt_register_project
            ? row.pgt_register_project.title.replace(
                "The VMCMU Postgraduate Training Program: ",
                ""
              )
            : ""
        }`}
        modalSize="modal-xl"
      >
        <div className="row">
          <div className="col-7">
            {row && row.pgt_user && row.pgt_register_project ? (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-3">ข้อมูลออกใบเสร็จรับเงิน</div>
                  <div>
                    {row.pgt_user.prefix} {row.pgt_user.fnameTh}{" "}
                    {row.pgt_user.lnameTh} ({row.pgt_user.role}) (
                    {row.pgt_user.idCard})
                  </div>
                </div>
                <textarea className="form-control" disabled readOnly>
                  {`${row.pgt_user.address} ต.${row.pgt_user.parish} อ.${row.pgt_user.district} จ.${row.pgt_user.county} ${row.pgt_user.zipcode} (${row.pgt_user.phone})`}
                </textarea>

                <button
                  onClick={(e) => handleSendReceipt(row.userId, row.titleId)}
                  className="btn btn-primary mt-3"
                >
                  <i className="fa-regular fa-paper-plane"></i>{" "}
                  เปลี่ยนสถานะการออกใบเสร็จ
                </button>
              </>
            ) : null}
          </div>
          <div className="col-5">
            <img
              src={config.apiPath + "/upload/PGT/Slip/" + row.transferSlip}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DashboardStaff;
