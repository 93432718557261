import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import { useLocation, useSearchParams } from 'react-router-dom';

function GenerateQrCode() {
    const location = useLocation();
    const [searchParams] = useSearchParams(location.search);

    const encodedUserId = searchParams.get("userId");
    const encodedEmail = searchParams.get("code");

    let userId = '';
    let email = '';
    let codeId = '';
    let codeEmail = '';

    if (encodedUserId && encodedEmail) {
        try {
            userId = atob(encodedUserId);
            email = atob(encodedEmail);
            codeId = userId.replace("heander", " ").trim().split(" ").pop();
            codeEmail = email.replace("heander", " ").trim().split(" ").pop();
        } catch (error) {
            console.error('Error decoding base64:', error);
        }
    } else {
        console.error('Missing query parameters');
    }

    const qrCodeRef = useRef(null);

    return (
        <div className="container-Qrcode">
            <div className="Qrcode-print" ref={qrCodeRef}>
                <QRCode value={`${location.search}`} className='QRCode mb-3' />
                <div className="">รหัสสมาชิก: {codeId}</div>
                <div className="">Email: {codeEmail}</div>
                <div className="mb-3">key: {location.key}</div>
                
                <div className="text-danger">Qr code นี้สามารถใช้ Check in เข้าร่วมงาน ทุกโครงการที่ท่านลงทะเบียนไว้</div>
                <div className="text-primary mb-3">กรุณาแคปหน้าจอเก็บไว้</div>
                {/* <button onClick={handleSnip}>Snipping</button> */}
            </div>
        </div>
    );
}

export default GenerateQrCode;
