import { Link } from "react-router-dom";
function ContentSidebar() {
  return (
    <>
      <aside className="control-sidebar control-sidebar-dark">
          <div className="p-2">
            <div className="text-center mb-3">
              เลือกข้อมูลที่ต้องการ
            </div>
            <Link to={''} className="btn btn-light w-100 mb-2">Vet</Link>
            <Link to={''} className="btn btn-light w-100 mb-2">Vet nurse</Link>
            <Link to={''} className="btn btn-light w-100 mb-2">Vet tech</Link>
            <Link to={''} className="btn btn-light w-100 mb-2">Scientist</Link>
          </div>
      </aside>
    </>
  );
}

export default ContentSidebar;
