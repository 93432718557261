import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../config";

const useFetchDataInfo = () => {
  const [info, setInfo] = useState({});
  const navigate = useNavigate();

  const fetchDataInfo = async () => {
  try {
    const info = await axios.get(
      config.apiPath + config.urlPathUser + "/authorization/info",
      config.headers()
    );
    if (info.data.success === true) {
      setInfo(info.data.payload);
      localStorage.setItem("codeId", info.data.payload.codeId);
    }
  } catch (e) {
    if (
      e.response?.data?.error === "Unauthorized" &&
      e.response?.data?.statusCode === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Verify expired verification",
        text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
        timer: 2000,
      }).then(() => {
        localStorage.clear();
        window.location.reload();
        navigate("/");
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "ติดตต่อเจ้าหน้าที่",
        text: e.code + " " + e.response.data.statusCode,
      }).then(()=> {
         navigate("/");
      })
    }
    
  }
  };

  return { info, fetchDataInfo };
};

export default useFetchDataInfo;
