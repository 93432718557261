import TemplateAdmin from "../component/TemplateAdmin";
import DataTable from "react-data-table-component";

import { useEffect, useState, useMemo } from "react";
import { Swalerror } from "../modules/SwalError";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import * as dayJs from "dayjs";
import Modal from "../../component/Modal";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

function ListNameRegi() {
  const [dataTable, setDataTable] = useState([]);
  const [project, setProject] = useState([]);
  const [titleText, setTitle] = useState("");
  const navigate = useNavigate();
  const currentDate = new Date();

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchDataProject();
  }, []);

  const fetchDataProject = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/list/home"
      );
      if (res.data.success === true) {
        setDataTable(res.data.results);
      }
    } catch (e) {
      Swalerror(e, navigate);
    }
  };

  const handleListName = async (id, title) => {
    try {
      setProject([]);

      const res = await axios.get(
        config.apiPath +
          config.urlPathProject +
          "/list/user/registe/" +
          btoa("heander " + id),
        config.headers_staff()
      );
      if (res.data.success === true && res.data.results) {
        setProject(res.data.results);
        setTitle(
          title.replace("The VMCMU Postgraduate Training Program: ", "")
        );
      }
    } catch (e) {
      Swalerror(e, navigate);
    }
  };

  const columns = [
    {
      name: "รหัสโครงการ",
      selector: (row) => row.id,
      width: "150px",
    },
    {
      name: "ชื่อโครงการ",
      selector: (row) =>
        row.title.replace("The VMCMU Postgraduate Training Program: ", ""),
      width: "800px",
    },
    {
      name: "ดูข้อมูล",
      cell: (row) => {
        return (
          <>
            <button
              data-bs-toggle="modal"
              data-bs-target="#modallistname"
              onClick={(e) => handleListName(row.id, row.title)}
              className="btn btn-primary"
            >
              ดูรายชื่อ
            </button>
          </>
        );
      },
    },
  ];

  const columnsProject = [
    {
      name: "รหัสสมาชิก",
      selector: (row) => row.userId,
    },
    {
      name: "ชื่อนามสกุล",
      selector: (row) =>
        `${row.pgt_user.prefix} ${row.pgt_user.fnameTh} ${row.pgt_user.lnameTh}`,
    },

    {
      name: "Role",
      selector: (row) => `${row.pgt_user.role}`,
    },
    {
      name: "Status",
      selector: (row) => `${row.sentTransferSlip}`,
    },
    {
      name: "ระยะเวลาแนบสลิป",
      cell: (row) => {
        // Check if autodate_cancel exists
        if (!row.autodate_cancel) {
          return null; // Return null if autodate_cancel doesn't exist
        }

        return (
          <>
            {new Date(currentDate) >= new Date(row.autodate_cancel) ? (
              <button
                onClick={() => handleCancel(row.userId, row.id)}
                className="btn btn-danger"
              >
                หมดเวลาแนบ
              </button>
            ) : null}
          </>
        );
      },
    },
  ];

  const data = dataTable;

  const dataProject = project;

  const textTitle = (
    <div className="d-flex justify-content-between mt-3">
      <div>ตารางข้อมูล</div>
    </div>
  );
  const downloadExcel = (dataProject) => {
    if (!dataProject || dataProject.length === 0) {
      Swal.fire({
        title: "ไม่มีข้อมูล",
        text: "ไม่มีข้อมูลสำหรับการส่งออก.",
        icon: "warning",
      });
      return;
    }

    const excelData = dataProject.map((row) => ({
      รหัสสมาชิก: row.userId,
      รหัสโครงการ: row.titleId,
      ชื่อนามสกุล: `${row.pgt_user.prefix} ${row.pgt_user.fnameTh} ${row.pgt_user.lnameTh}`,
      CE: `${row.pgt_user.cecode ?? null}`,
      สถานะ: row.pgt_user.role,
      สถานะการชำระเงิน: row.sentTransferSlip,
      email: row.pgt_user.email,
      เบอร์ติดต่อ: row.pgt_user.phone,
    }));

    // สร้าง worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // สร้าง workbook และเพิ่ม worksheet ลงไป
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Projects");

    // สร้างไฟล์ Excel และดาวน์โหลด
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // สร้างลิงก์เพื่อดาวน์โหลดไฟล์
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Project_PGT_${dayJs(new Date()).format(
      config.dateFormat
    )}.xlsx`;
    link.click();
  };

  const actionsMemo = useMemo(
    () => (
      <button
        className="btn btn-secondary"
        onClick={() => downloadExcel(dataProject)}
      >
        {" "}
        <i className="fa fa-file-excel"></i> Export to Excel
      </button>
    ),
    [dataProject]
  );

  const handleCancel = async (userId, id) => {
    try {
      const payload = {
        userId: userId,
        id: id,
      };

      const res = await axios.delete(
        `${config.apiPath}${config.urlPathProject}/delect/prj/${payload.userId}/${payload.id}`,
        config.headers_staff()
      );
      if (res.data.success === true) {
        const btn = document.getElementsByClassName("btnClose");
        for (let i = 0; i < btn.length; i++) btn[i].click();
        setProject([]);
        fetchDataProject();
      }
    } catch (e) {
      Swalerror(e, navigate);
    }
  };

  return (
    <>
      <TemplateAdmin>
        <div className="card">
          <div className="card-body">
            <div className="d-block">
              <div className="border-bottom">รายชื่อผู้ลงทะเบียนโครงการ</div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
                <DataTable
                  columns={columns}
                  data={data}
                  title={textTitle}
                  pagination
                  paginationPerPage={10}
                />
              </div>
            </div>
          </div>
        </div>
      </TemplateAdmin>
      <Modal
        id="modallistname"
        title={`ชื่อโครงการ: ${titleText}`}
        modalSize="modal-xl"
      >
        <DataTable
          columns={columnsProject}
          data={dataProject}
          title={textTitle}
          actions={actionsMemo}
          pagination
          paginationPerPage={10}
        />
      </Modal>
    </>
  );
}
export default ListNameRegi;
