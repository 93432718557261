import { Link, useNavigate } from "react-router-dom";
import logo from "./../assets/images/Postgraduate.svg";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import encBase64 from "crypto-js/enc-base64";

function UserSignin() {
  const [checker, setChecker] = useState(false);
  const [checker2, setChecker2] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState("");
  const [resetpwd, setResetPwd] = useState(false);
  const [checkemail, setCheckEmail] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmNePassword, setconfirmNePassword] = useState("");

  const navigrate = useNavigate()

  const handleCheckboxChange = (e) => {
    setForgot(e.target.checked);
  };

  const handleCheck = async (event) => {
    try {
      event.preventDefault();

      const payload = {
        email: email,
      };

      const res = await axios.post(
        config.apiPath + config.urlPathUser + "/check/email",
        payload
      );

      if (res.data.success === true) {
        setResetPwd(res.data.success);
        setCheckEmail(res.data.email);
      } else {
        Swal.fire({
          icon: "error",
          text: res.data.message,
        });
        setResetPwd(res.data.success);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: e,
      });
    }
  };

  const passwordSHA256 = () => {
    const hashDigest = sha256(checkemail + newpassword).toString();
    const eBase64 = encBase64.stringify(
      hmacSHA512(hashDigest, config.privateKey)
    );
    return eBase64;
  };

  const handleChangePassword = async (event) => {
    try {
      event.preventDefault();

      if (newpassword !== confirmNePassword) {
        Swal.fire({
          icon: "error",
          text: "รหัสไม่ตรงกันกรุณาตรวจสอบอีกครั้ง",
        });
      } else {

        const confirmChangePassword = await Swal.fire({
          icon: 'question',
          title: 'ยืนยันการเปลี่ยนแปลงรหัสผ่านใหม่ ใช่หรือไม่',
          showCancelButton: true,
          cancelButtonText: 'No',
          showConfirmButton:true,
          confirmButtonText: 'Yes',
        })
        if (confirmChangePassword.isConfirmed) {
          const payload = {
            email: checkemail,
            pwd: passwordSHA256(newpassword),
          };

          const res = await axios.put(config.apiPath + config.urlPathUser + '/change/password' ,payload)

          if (res.data.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'เปลี่ยนรหัสผ่านใหม่สำเร็จ',
              text: 'ทำการล็อคอินด้วยรหััสผ่านใหม่ อีกครั้ง'
            }).then(()=> {
              window.location.reload()
            })
          
          }
        }
        

      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: e,
      });
    }
  };

  const handleSignin = async (event) => {
    try {
      event.preventDefault();

      const payload = {
        email: checkemail,
        pwd: passwordSHA256(newpassword),
      };

      const res = await axios.post(config.apiPath + config.urlPathUser + '/signin', payload)

      if (res.data.success === true) {
        const token = res.data.token.access_token;
        localStorage.setItem('token_pgt', token)
        navigrate('/')
      } else {
         Swal.fire({
           icon: "error",
           title: "Error",
           text: ` Error System Login email or password is invalid.`,
         });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: ` Error System Login ${e.error}`,
      });
    }
  };
  return (
    <>
      <div className="container py-3">
        <div className="d-flex align-items-center ">
          <img src={logo} className="img-logo" alt="" />
          <div className="d-block ms-2">
            <span className="h1">Postgraduate Education Center</span>
            <br />
            <span className="h6 ms-1">
              Faculty of Veterinary Medicine Chiang Mai University
            </span>
          </div>
        </div>
      </div>
      <div className="border-top-navbar">
        <div className="container d-flex justify-content-between">
          <ul className="header-navbar">
            <li className="header-nav-item active">Facebook Page</li>
          </ul>
          <ul className="header-navbar">
            <li className="header-nav-item active">สำหรับสมาชิก</li>
          </ul>
        </div>
        <div className="text-center"></div>
      </div>

      <div className="container container-box py-3">
        <div className="card card-staff-box">
          <div className="card-body">
            <form onSubmit={handleSignin} className="card-staff-form">
              <label htmlFor="" className="form-label mb-3">
                Postgraduate Education Center
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  onChange={e => setCheckEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type={checker2 ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  minLength={6}
                  onChange={e => setNewPassword(e.target.value)}
                  required
                />
                <span className="input-group-text">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="showpassword"
                      onChange={(e) => setChecker2(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="showpassword">
                      Show password
                    </label>
                  </div>
                </span>
              </div>
              <button type="submit" className="btn btn-primary mt-3 w-100">
                <i className="fa fa-key me-2"></i>
                Sign In
              </button>
            </form>
            <div className="usersignin-register">
              <Link to={"/registermember"}>
                {" "}
                <i className="fa fa-user"></i> สมัครสมาชิก
              </Link>
              <Link to={"/registermember"}>
                {" "}
                <i className="fa fa-home"></i> กลับหน้าหลัก
              </Link>
              <div className="form-check">
                <input
                  className="form-check-input ClickChange"
                  type="checkbox"
                  checked={forgot}
                  onChange={handleCheckboxChange}
                  id="flexCheckDefault"
                  hidden
                />
                <label
                  className="form-check-label"
                  for="flexCheckDefault"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-key"></i> ลืมรหัสผ่าน
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {forgot ? (
        <div className="container container-box mb-3">
          <div className="card card-staff-box">
            <div className="card-body">
              <form
                onSubmit={handleCheck}
                className="card-staff-form was-validated"
              >
                <label htmlFor="" className="form-label mb-3">
                  ตรวจสอบข้อมูลเพื่อทำการรีเซ็ตรหัสผ่านใหม่
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="กรอกอีเมลที่ลงทะเบียน"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit" className="btn btn-primary">
                    ตรวจสอบ
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {resetpwd ? (
        <div className="container container-box mb-3">
          <div className="card card-staff-box">
            <div className="card-body">
              <form
                onSubmit={handleChangePassword}
                className="card-staff-form was-validated"
              >
                <label htmlFor="" className="form-label mb-3">
                  Change Password
                </label>
                <input
                  type={checker ? "text" : "password"}
                  className="form-control mb-3"
                  placeholder="New password"
                  minLength={6}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <input
                  type={checker ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm New password"
                  minLength={6}
                  onChange={(e) => setconfirmNePassword(e.target.value)}
                  required
                />
                <div className="d-flex justify-content-end">
                  <div className="form-check mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="showpassword2"
                      onChange={(e) => setChecker(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="showpassword2">
                      Show password
                    </label>
                  </div>
                </div>
                {newpassword && confirmNePassword ? (
                  <>
                    <button className="btn btn-primary">
                      ยืนยันเปลี่ยนรหัสใหม่
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="container ">
        <div className="card card-footer-custom">
          <div className="card-footer-layout">
            <div className="card-body">
              <div className="title">ล็อคอินเข้าสู่ระบบ</div>
              <div className="subtitle">Postgraduate Education Center</div>
              <br />
              <div className="email">
                <div style={{ fontWeight: 500 }}>
                  ติดปัญหาในการใช้งานกรุณาติดต่อ
                </div>
              </div>
              <div className="email">
                <span>Phone:</span>{" "}
                <Link to={"tel:+66 5398 4114"}>+66 5398 4114</Link> (ภพ)
              </div>
            </div>
            <div className="card-body text-end ">
              <div className="footer-staff">
                <Link to={"/signinstaff"} className="staff-signin">
                  <i className="fa fa-book me-1"></i>
                  คู่มือการใช้งาน
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserSignin;
