import { Link } from "react-router-dom";

function Footer() {


  const handleClenLocalstoray = () => {
    localStorage.removeItem("token_pgt");
    localStorage.removeItem("codeId")
  }

  return (
    <>
      <div className="container">
        <div className="card card-footer-custom">
          <div className="card-footer-layout">
            <div className="card-body">
              <div className="title">Postgraduate Education Center</div>
              <div className="subtitle">
                Faculty of Veterinary Medicine Chiang Mai University
              </div>
              <br />
              <div className="email">
                <span>Email:</span>{" "}
                <Link to={"mailto:PGT.CMU@gmail.com"}>PGT.CMU@gmail.com</Link>{" "}
              </div>
              <div className="email">
                <span>Phone:</span>{" "}
                <Link to={"tel:+66 5398 4114"}>+66 5398 4114</Link>{" "}
              </div>
            </div>
            <div className="card-body text-end ">
              <div className="footer-staff">
                <Link to={'/signinstaff'} onClick={handleClenLocalstoray} className="staff-signin">
                  <i className="fa fa-key me-1"></i>
                  Sign in staff
                </Link>
              </div>
            </div>
          </div>
        </div>

        <ul className="footer-pdpa">
          <li>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</li>
          <li>Privacy policy</li>
        </ul>
      </div>
    </>
  );
}
export default Footer;
