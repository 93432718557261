import Footer from "./Footer";
import Navbar from "./Navbar";
import { publish } from "../inc/DataDetail";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import Modal from "./Modal";
import vdo1 from "./../assets/vdo/path1.mp4";
import vdo2 from "./../assets/vdo/path2.mp4";

function Template(props) {
  const [countMember, setCountMember] = useState("");

  useEffect(() => {
    fetchCount();
  }, []);

  const fetchCount = async () => {
    try {
      const count = await axios.get(
        config.apiPath + config.urlPathUser + "/member/count"
      );
      if (count.data.success === true) {
        setCountMember(count.data.results);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <>
      <Navbar />

      <div className="bg-img">
        <div className="container py-3">
          <div className="row g-2">
            <div className="col-lg-9 order-lg-1  order-2 bg-white z-customer">
              {props.children}
            </div>
            <aside className="col-lg-3 order-lg-2  order-1 aside-stack ">
              <div className="card aside-card">
                <div className="aside-menber">
                  สมาชิกทั้งหมด: {countMember} คน
                </div>
              </div>
              <div className="card aside-card">
                <div className="aside-title">
                  <div className="aside-text">
                    ข้อกำหนดและเงื่อนไขการคืนเงิน
                  </div>
                </div>

                <Link to={"/refunds"}>
                  <button className="btn-aside">
                    อ่านรายละเอียดการชำระเงิน
                  </button>
                </Link>
              </div>
              <div className="card aside-card">
                <div className="aside-title">
                  <div className="aside-text">คู่มือสำหรับสมาชิก (VDO)</div>
                </div>
                {publish
                  ? publish.map((item, index) => (
                      <Link
                        key={index}
                        data-bs-target={item.link}
                        data-bs-toggle="modal"
                      >
                        <button className="btn-aside">{item.name}</button>
                      </Link>
                    ))
                  : null}
              </div>

              {/* <div className="card aside-card">
                <div className="aside-title">
                  <div className="aside-text">กรณีสมัครเอง</div>
                </div>
                <Link
                  to={""}
                  target="_blank"
                  className="btn btn-primary btn-sm mt-2"
                >
                  {" "}
                  Download (PDF)
                </Link>
              </div>
              <div className="card aside-card">
                <div className="aside-title">
                  <div className="aside-text" style={{ fontSize: 17 }}>
                    กรณีได้รับการสนับสนุนจากบริษัท
                  </div>
                </div>
                <Link
                  to={""}
                  target="_blank"
                  className="btn btn-primary btn-sm mt-2"
                >
                  {" "}
                  Download (PDF)
                </Link>
              </div> */}
              <div className="card aside-card">
                <div className="aside-title">
                  <div className="aside-text">วิธีสร้าง QR Code Check-in</div>
                </div>
                <Link
                  to={"https://drive.google.com/file/d/1oR5rObqETZ_ZuAuzp8w-sUxuL6vHCD3V/view?usp=sharing"}
                  target="_blank"
                  className="btn btn-primary btn-sm mt-2 text-start"
                >
                  {" "}
                  Download (PDF)
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        id="modalManual1"
        title="วิดีโอวิธีสมัครสมาชิก"
        modalSize="modal-xl"
      >
        <div className="container">
          <div className="video-container">
            <video width="100%" controls>
              <source src={vdo1} type="video/mp4" />
            </video>
          </div>
        </div>
      </Modal>
      <Modal
        id="modalManual2"
        title="วิดีโอวิธีลงทะเบียนโครงการ"
        modalSize="modal-xl"
      >
        <div className="video-container">
          <video width="100%" controls>
            <source src={vdo2} type="video/mp4" />
          </video>
        </div>
      </Modal>
    </>
  );
}
export default Template;
