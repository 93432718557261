import Swal from "sweetalert2";
import Template from "../component/Template";
import axios from "axios";
import config from "../config";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as dayJs from "dayjs";

import useFetchDataInfo from "../modules/FetchInfo";

function Home() {
  const [project, setProject] = useState([]);
  const { info, fetchDataInfo } = useFetchDataInfo();
  // const [titleId, setTitleId] = useState(null)
  const currentDate = new Date();

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token_pgt")) {
      fetchDataInfo();
      fetchDataProject();
    } else {
      fetchDataProject();
    }
  }, []);

  const fetchDataProject = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/list/home"
      );

      if (res.data.success === true) {
        setProject(res.data.results);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "fetch data project",
      });
    }
  };

  const Swalerror = async (e) => {
    if (
      e.response?.data?.error === "Unauthorized" &&
      e.response?.data?.statusCode === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Verify expired verification",
        text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
        timer: 2000,
      }).then(() => {
        localStorage.clear();
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "ติดตต่อเจ้าหน้าที่",
        text: e.code + " " + e.response.data.statusCode,
      }).then(() => {
        navigate("/");
      });
    }
  };

  const handleRegiProject = async (titleId, title) => {
    try {
      const confrimRegi = await Swal.fire({
        icon: "question",
        title: "ยืนยันลงทะเบียนเข้าร่วมโครงการ",
        text: title,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "ลงทะเบียน",
        cancelButtonText: "ยกเลิก",
      });

      if (confrimRegi.isConfirmed) {
        let timerInterval;
        const payload = {
          codeId: info.codeId,
          titleId: titleId,
        };

        Swal.fire({
          title: `ระบบกำลังส่งอีเมลยืนยันการลงทะเบียน! ${info.codeId}`,
          html: "Loading..",
          timer: 1000000,
          timerProgressBar: true,
          showConfirmButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,

          didOpen: async () => {
            try {
              const res = await axios.post(
                config.apiPath + config.urlPathProject + "/regi/prj",
                payload,
                config.headers()
              );

              if (res.data.success === true) {
                Swal.fire({
                  icon: "success",
                  title: "ลงทะเบียนสำเร็จ",
                  html: `<h4>โปรดตรวจสอบข้อมูลการลงทะเบียนของท่านที่<span style="color: red;"> <br> ปุ่มตรวจสอบข้อมูลลงทะเบียน</span> </h4>`,
                  showConfirmButton: true,
                  confirmButtonText: "ตรวจสอบข้อมูลลงทะเบียน",
                  allowEnterKey: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then(() => {
                  navigate("/check/Data");
                });
              } else if (
                res.data.success === false &&
                res.data.respCode === 409
              ) {
                Swal.fire({
                  icon: "info",
                  title: "ท่านได้สามารถโครงการนี้แล้ว",
                  showConfirmButton: true,
                  confirmButtonText: "ตรวจสอบข้อมูลลงทะเบียน",
                }).then(() => {
                  navigate("/check/Data");
                });
              }
            } catch (e) {
              Swalerror(e);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      }
    } catch (e) {
      Swalerror(e);
    }
  };

  return (
    <>
      <Template>
        <div className="container"></div>
        <main className="container pt-4">
          <div className="header-title">
            <div className="header-text">
              โครงการสัมมนา และ อบรมเชิงปฏิบัติการ
            </div>
          </div>

          <div className="row g-2 mt-3">
            <div className="col-lg-12">
              {project.length > 0 ? (
                project.map((item) => {
                  const openDate = new Date(item.open_regi);
                  const closeDate = new Date(item.close_regi);

                  if (currentDate > closeDate) {
                    return (
                      <>
                        <div className="card">
                          <div className="card-body">
                            {item.title} <span className="text-danger">(Close)</span>
                          </div>
                        </div>
                      </>
                    ); // Don't render if currentDate is greater than closeDate
                  }

                  return (
                    <div key={item.id} className="card home-card">
                      <div className="card-body">
                        <div className="home-title-detail">
                          <div className="row g-2">
                            <div className="col-lg-4 col-md-6 col-6 mx-auto">
                              <img
                                className="img-fluid"
                                src={`${config.apiPath}/filePGT/${item.image}`}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8 col-md-12 col-12">
                              <div className="home-detail-subtitle">
                                <div className="home-text-title">
                                  {item.title}
                                </div>
                                <div
                                  className="home-text-subtitle"
                                  style={{ fontSize: 20 }}
                                >
                                  {item.subtitle}
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                  <div
                                    className="text-bg-success p-1 rounded text-center"
                                    style={{ width: 200, fontSize: 14 }}
                                  >
                                    ค่าลงทะเบียน:{" "}
                                    {item.price_regi.toLocaleString("th-TH")}{" "}
                                    บาท
                                  </div>
                                </div>

                                {localStorage.getItem("token_pgt") ? (
                                  (() => {
                                    if (
                                      item.count_regi ===
                                        item.PGT_title_Project.length ||
                                      currentDate > closeDate
                                    ) {
                                      return (
                                        <div className="home-title-btn">
                                          <div className="title-text">
                                            จำนวนผู้ลงทะเบียน{" "}
                                            <i className="fa fa-user"></i>{" "}
                                            {item.PGT_title_Project.length}
                                          </div>
                                          <div className="btn btn-secondary btn-sm disabled">
                                            ปิดรับสมัครโครงการ
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      currentDate >= openDate &&
                                      currentDate <= closeDate
                                    ) {
                                      return (
                                        <div className="home-title-btn">
                                          <div className="title-text">
                                            จำนวนผู้ลงทะเบียน{" "}
                                            <i className="fa fa-user"></i>{" "}
                                            {item.PGT_title_Project.length}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleRegiProject(
                                                item.id,
                                                item.title
                                              )
                                            }
                                            className="btn btn-primary btn-sm"
                                          >
                                            ลงทะเบียนเข้าร่วมโครงการ
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="home-title-btn">
                                          <div className="title-text">
                                            เปิดรับลงทะเบียนในวันที่{" "}
                                            <i className="fa fa-calendar"></i>{" "}
                                            {dayJs(item.open_regi).format(
                                              config.dateFormatTime
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  })()
                                ) : (
                                  <div className="home-title-btn-on-login">
                                    <div className="title-text">
                                      จำนวนผู้ลงทะเบียน{" "}
                                      <i className="fa fa-user"></i>{" "}
                                      {item.PGT_title_Project.length}
                                    </div>
                                    <Link to="/vet/signin">
                                      <i className="fa fa-lock"></i>{" "}
                                      เข้าสู่ระบบก่อนลงทะเบียน
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </main>
      </Template>
    </>
  );
}
export default Home;
