import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import encBase64 from "crypto-js/enc-base64";

const passwordSHA256 = (email, pwd, privateKey) => {
  const hashDigest = sha256(email + pwd).toString();
  const eBase64 = encBase64.stringify(hmacSHA512(hashDigest, privateKey));
  return eBase64;
};

export default passwordSHA256;
