import TemplateAdmin from "../component/TemplateAdmin";
import { useEffect, useState, useMemo } from "react";
import { Swalerror } from "../modules/SwalError";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import config from "../../config";
import Swal from "sweetalert2";

const Sponsor = () => {
  const [dataTable, setDataTable] = useState([]);
  const [role, setRole] = useState("0");
  const [searchText, setSearchText] = useState("");
  const [project, setProject] = useState([]);
  const [addProject, setAddProject] = useState({});

  const navigate = useNavigate();
  const fetchDataUser = async () => {
    try {
      const base64Role = btoa("heander " + role);
      const res = await axios.get(
        config.apiPath + config.urlPathUser + "/list/user/" + base64Role,
        config.headers_staff()
      );
      if (res.data.success === true) {
        setDataTable(res.data.result);
      } else if (res.data.respCode === 209) {
        Swal.fire({
          icon: "info",
          title: "ไม่มีข้อมูล",
        });
      }
    } catch (e) {
      Swalerror(e, navigate);
    }
  };

  const fetchDataProject = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/list/projects"
      );
      if (res.data.success === true) {
        setProject(res.data.results);
      }
    } catch (e) {
      // Swalerror(e, navigate);
    }
  };

  useEffect(() => {
    fetchDataUser();
    fetchDataProject();
  }, []);

  const handleSaveProject = async (codeId) => {
    try {
      const payload = {
        userId: codeId,
        titleId: addProject
      }
      const res = await axios.post(`${config.apiPath}/api/pgt/sponsor/regi`,payload, config.headers_staff())
      if (res.data.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'เพิ่มโครงการสำเร็จ',
          timer: 1000
        })
      }
      if (res.data.respCode === 409) {
        Swal.fire({
          icon: 'info',
          title: 'มีข้อมูลโครงการนี้แล้ว',
          text: 'เลือกโครงการใหม่'
        })
      }
    } catch (e) {
      // Swalerror(e, navigate);
    }
  };

  const data = dataTable
    ? dataTable.filter((item) =>
        Object.values(item).some((val) => {
          return (
            (typeof val === "string" &&
              val.toLowerCase().includes(searchText.toLowerCase())) ||
            (typeof val === "number" && val === parseInt(searchText))
          );
        })
      )
    : [];

  const columns = [
    {
      name: "รหัสสมาชิก",
      selector: (row) => row.codeId,
      width: "150px",
    },
    {
      name: "ชื่อ",
      selector: (row) => row.fnameTh + " " + row.lnameTh,
      width: "250px",
    },
    {
      name: "ce",
      selector: (row) => row.cecode,
      width: "200px",
    },
    {
      name: "ลงทะเบียนโครงการ",
      cell: (row) => {
        return (
          <>
            <div className="input-group">
              <select
                onChange={(e) => setAddProject(e.target.value)}
                className="form-select"
              >
                <option value="">Select..</option>
                {project.length > 0
                  ? project.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title.replace(
                          "The VMCMU Postgraduate Training Program:",
                          ""
                        )}
                      </option>
                    ))
                  : null}
              </select>
              <button
                type="button"
                onClick={(e) => handleSaveProject(row.codeId)}
                className=" input-group-text"
              >
                บันทึก
              </button>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <TemplateAdmin>
        <div className="row g-2 mt-2">
          <div className="col-lg-6 me-auto">
            <h4>เพิ่มชื่อเข้าโครงการส่วน Sponsor</h4>
          </div>
          <div className="col-lg-6 ms-auto">
            <div className="input-group">
              <label htmlFor="search" className="input-group-text">
                <i className="fa fa-search fa-1x me-2"></i>
              </label>
              <input
                type="text"
                name="search"
                className="form-control"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <DataTable
              columns={columns}
              data={data}
              // title={"ค้นหาข้อมูล"}
              // actions={actionsMemo}
              pagination
              paginationPerPage={10}
            />
          </div>
        </div>
      </TemplateAdmin>
    </>
  );
};
export default Sponsor;
