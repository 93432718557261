import { useEffect } from "react";
import useFetchDataInfoStaff from "../modules/useFetchDataInfo";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function NavbarAdmin() {
  const { infoStaff, fetchDataInfoStaff } = useFetchDataInfoStaff();
  const navigrate = useNavigate();

  useEffect(()=> {
    fetchDataInfoStaff();
  }, [])

  const handleSignout = async () => {
  try {
    const confirmSignout = await Swal.fire({
      icon: 'question',
      title: 'ยืนยันออกจากระบบ ใช่หรือไม่',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'ออกระบบ',
      cancelButtonText: 'ยกเลิก'
    })
      
      if (confirmSignout.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'ทำการออกระบบสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(()=> {
          localStorage.removeItem('token_pgt_staff')
          navigrate('/signinstaff')        
        })
    }

  } catch (e) {
    
  }
  }

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* <!-- Left navbar links --> */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" data-widget="pushmenu" href="#" role="button">
              <i className="fas fa-bars"></i>
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link href="index3.html" className="nav-link">
              {infoStaff && infoStaff.infoSub && infoStaff.infoSub.fname}
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link onClick={handleSignout}  className="nav-link">
              <i className="fa fa-lock"></i> {" "}
              ออกระบบ
            </Link>
          </li>
        </ul>

        {/* <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto">
          {/* <!-- Navbar Search --> */}
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="navbar-search"
              href="#"
              role="button"
            >
              <i className="fas fa-search"></i>
            </Link>
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control form-control-navbar"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search"></i>
                    </button>
                    <button
                      className="btn btn-navbar"
                      type="button"
                      data-widget="navbar-search"
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <li className="nav-item">
            <Link className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="control-sidebar"
              data-controlsidebar-slide="true"
              href="#"
              role="button"
            >
              <i className="fas fa-th-large"></i>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default NavbarAdmin;
