import TemplateAdmin from "../component/TemplateAdmin";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import config from "../../config";
import { Swalerror } from "../modules/SwalError";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import * as dayJs from 'dayjs'

function ListNameCheckIn() {
  const [dataTable, setDataTable] = useState([]);

  const [project, setProject] = useState([]);
  const [titleId, setTitleId] = useState("0");

  const navigeta = useNavigate();

  const data = dataTable;

  const columns = [
    {
      name: "รหัสสมาชิก",
      selector: (row) => row.userId,
    },
    {
      name: "รหัสโครงการ",
      selector: (row) => row.titleId,
    },
    {
      name: "วันที่ลงทะเบียน",
      selector: (row) => row.dateCheckin,
    },
    {
      name: "เวลา",
      selector: (row) => row.timeCheckin,
    },
  ];

  useEffect(() => {
    fetchDataProject();
    fetchDataCheckIn(titleId);
  }, [titleId]);

  

  const fetchDataProject = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/list/home"
      );
      if (res.data.success === true) {
        setProject(res.data.results);
      }
    } catch (e) {
      Swalerror(e, navigeta);
    }
  };

  const fetchDataCheckIn = async (titleId) => {
    try {
      const res = await axios.get(
        config.apiPath +
          config.urlPathCheckIn +
          "/list/name/check/in/" +
          btoa("heander " + titleId),
        config.headers_staff()
      );
      if (res.data.success === true) {
        setDataTable(res.data.results);
      } else if (res.data.respCode === 204) {
        Swal.fire({
          icon: "info",
          title: "ไม่มีข้อมูล",
        });
      }
    } catch (e) {
      Swalerror(e , navigeta)
    }
  };

  const downloadCSV = (data) => {
    const csvData = data.map((row) => ({
        รหัสสมาชิก: row.userId,
        รหัสโครงการ: row.titleId,
        ชื่อนามสกุล: `${row.pgt_user.prefix} ${row.pgt_user.fnameTh} ${row.pgt_user.lnameTh}`,
        ce: row.pgt_user.cecode,
        สถานะ: row.pgt_user.role,
        วันที่ลงทะเบียน: `${row.dateCheckin} ${row.timeCheckin}`,
        ลงชื่อ: `${row.pgt_user.fnameTh} ${row.pgt_user.lnameTh}`,
        
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(","), // แถวหัวข้อ
      ...csvData.map((row) => Object.values(row).join(",")), // แถวข้อมูล
    ].join("\n");

    // เพิ่ม BOM เพื่อให้แน่ใจว่าไฟล์จะถูกอ่านเป็น UTF-8
    const bom = "\uFEFF";
    const blob = new Blob([bom + csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${dayJs(new Date()).format(config.dateFormat)}.csv`;
    link.click();
  };

  const actionsMemo = useMemo(
    () => (
      <button className="btn btn-secondary" onClick={() => downloadCSV(data)}>
        {" "}
        <i className="fa fa-file"></i> Export
      </button>
    ),
    [data]
  );

  return (
    <>
      <TemplateAdmin>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-start">
              <div className="h4">รายชื่อผู้เข้าร่วมงานโครงการ</div>
              <div className="ms-3">
                <select
                  className="select-form"
                  onChange={(e) => setTitleId(e.target.value)}
                >
                  <option value="0">เลือกโครงการที่ต้องการค้นหา</option>
                  {project.length > 0
                    ? project.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.title.replace(
                            "The VMCMU Postgraduate Training Program:",
                            ""
                          )}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>
            <div className="mt-2">
              <DataTable
                columns={columns}
                data={data}
                title={"ตารางข้อมูล"}
                actions={actionsMemo}
                pagination
                paginationPerPage={10}
              />
            </div>
          </div>
        </div>
      </TemplateAdmin>
    </>
  );
}
export default ListNameCheckIn;
