import Template from "../component/Template";

function Contact () {
    return (
        <>
            <Template>
            <div className="header-title">
          <div className="header-text">
            ติดต่อสอบถามข้อมูลเพิ่มเติม 
          </div>
        </div>

        <div className="card mt-4">
            <div className="card-body">
                <div className="h4" style={{color: '#0f4d82', fontWeight: 600}}>เบอร์ติดต่อ (ในวันและเวลาราชการ)</div>
                <p className="text-muted h5">053-948-114 (พี่โป)</p>
            </div>
        </div>
            </Template>

        </>
    )
}
export default Contact;