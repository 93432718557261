import Template from "../../component/Template";
import { Link } from "react-router-dom";
import { roles } from "../../inc/DataDetail";


function RegisterMember () {

    return (
      <>
        <Template>
          <div className="header-title">
            <div className="header-text">
              REGISTRATION : Postgraduate Education Center
            </div>
          </div>
          <div className="member-box"></div>
          <div className="card member-card">
            <div className="col-6">
              <div className="row g-2 member-box-item">
                <div className="member-title">Please Select Your Role</div>
                {roles.map((item, index) => (
                  <div key={index} className="col-12 member-text">
                    <Link to={item.link} className="btn btn-primary mb-2">
                      {item.name}
                    </Link>
                  </div>
                ))}

                <Link to={"/"} className="member-link">
                  Return home
                </Link>
              </div>
            </div>
          </div>
        </Template>
      </>
    );
}
export default RegisterMember;