import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Scanner from "../modules/Scanner";
import config from "../config";

import "./../css/style.css";

function FromCheckIn() {
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);
  const navirate = useNavigate();

  const getUrl = atob(searchParams.get("typeId"));
  const urlId = getUrl.replace("heander", "").trim().split(" ").pop();

  const [title, setTitle] = useState("");
  const [counst, setCount] = useState(null);
  const [listName, setListName] = useState([]);

  useEffect(() => {
    if (urlId) {
      fetchDataTitle();
    } else {
      navirate("/fromcreate/check/in");
    }
  }, []);

  const scannerRef = useRef(null);
  const audioRef = useRef(null); // เพิ่ม ref สำหรับ Audio
  const audioReffail = useRef(null); // เพิ่ม ref สำหรับ Audio

  const handleScan = async (result) => {
    try {
      if (result && result[0].rawValue) {
        const urlParams = new URLSearchParams(result[0].rawValue);

        // ตรวจสอบว่ามี userId และ code ใน URL parameters หรือไม่
        if (!urlParams.has("userId") || !urlParams.has("code")) {
          Swal.fire({
            icon: "error",
            title: "QR CODE ไม่ถูกต้อง",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            if (scannerRef.current) {
              scannerRef.current.resumeScanning();
            }
          });
          if (audioReffail.current) {
            audioReffail.current.play();
          }
          return; // หยุดการดำเนินการต่อ
        }

        const userId = atob(urlParams.get("userId"));
        const code = atob(urlParams.get("code"));

        const encodeUserId = userId
          .replace("header", "")
          .trim()
          .split(" ")
          .pop();
        // const encodeCode = code.replace("header", "").trim().split(" ").pop();

        // ตรวจสอบว่า userId และ code มีค่าหรือไม่
        if (!userId || !code) {
          Swal.fire({
            icon: "error",
            title: "ข้อมูลใน QR CODE ไม่ถูกต้อง",
          });
          if (audioReffail.current) {
            audioReffail.current.play();
          }
          return; // หยุดการดำเนินการต่อ
        }

        const payload = {
          userId: encodeUserId,
          titleId: urlId,
        };

        const res = await axios.post(
          config.apiPath + config.urlPathCheckIn + "/check/in",
          payload
        );

        if (res.data.respCode === 400) {
          if (audioReffail.current) {
            audioReffail.current.play();
          }
          Swal.fire({
            icon: "error",
            title: "Check in fail",
            text: "กรุณาาติดต่อเจ้าหน้าที่",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            if (scannerRef.current) {
              scannerRef.current.resumeScanning();
            }
          });
        } else if (res.data.respCode === 409) {
          if (audioReffail.current) {
            audioReffail.current.play();
          }
          Swal.fire({
            icon: "info",
            title: "You have already checked in.",
            text: "ท่านได้ทำการเช็คอินซ้ำ",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            if (scannerRef.current) {
              scannerRef.current.resumeScanning();
            }
          });
        } else {
          // เล่นเสียงแจ้งเตือน
          if (audioRef.current) {
            audioRef.current.play();
          }
          fetchDataTitle();
          Swal.fire({
            icon: "success",
            title: "Check in success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            // เรียกใช้ resumeScanning ของคอมโพเนนต์ Scanner
            if (scannerRef.current) {
              scannerRef.current.resumeScanning();
            }
          });
        }
      }
    } catch (e) {
      if (audioReffail.current) {
        audioReffail.current.play();
      }
      Swal.fire({
        icon: "error",
        title: "You have not registered for this project.",
        text: "ท่านไม่ได้ลงทะเบียนโครงการนี้ (กรุณาติดตต่อเจ้าหน้าที่เพื่อตรวจสอบข้อมูล)",
        timer: 2000,
        showConfirmButton: false,
      }).then(() => {
        if (scannerRef.current) {
          scannerRef.current.resumeScanning();
        }
      });
    }
  };

  const fetchDataTitle = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/list/title/" + urlId
      );
      if (res.data.success) {
        setTitle(res.data.result);
        setCount(res.data.counts);
        setListName(res.data.lists);
      } else {
        navirate("/fromcreate/check/in");
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: e,
      });
    }
  };

  return (
    <div className="container py-2">
      <div className="card">
        <div className="scanner-box-title">
          <div className="scanner-text-title">
            The VMCMU Postgraduate Training Program:
          </div>
          <div className="scanner-text-subtitle">
            {title && title[0].title
              ? title[0].title.replace(
                  "The VMCMU Postgraduate Training Program: ",
                  ""
                )
              : null}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="box-scanner">
                <div className="col-12">

                  <div>
                    <Scanner ref={scannerRef} onScan={handleScan} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="">
                      <div className="scanner-count">
                        <div className="box-count-text">
                          <i className="fa fa-user"></i> จำนวนผู้เข้าร่วมงาน{" "}
                        </div>
                        <div className="box-count" style={{ fontSize: 26, color: '#fff' }}>
                          {counst ? counst : 0} 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="scanner-text-name">
                รายชื่อผู้เข้าร่วมงาน{" "}
                <span className="text-primary">{`(${new Date().toDateString()})`}</span>
              </div>
              <div className="card p-2 scroll-container">
                <div className="scroll-content">
                  {listName !== undefined && listName.length > 0
                    ? listName.map((item, index) => (
                        <div key={item.id}>{`${index + 1}. ${
                          item.pgt_user.prefix
                        } ${item.pgt_user.fnameTh} ${item.pgt_user.lnameTh} (${
                          item.pgt_user.role
                        })`}</div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* เพิ่ม Audio Element */}
      <audio ref={audioRef} src="/sounds/1-CheckinSuc.mp3" />
      <audio ref={audioReffail} src="/sounds/0-Checkinfai.mp3" />
    </div>
  );
}

export default FromCheckIn;
