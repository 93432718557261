import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./pages/Home";
import RegisterMember from "./pages/User/Regi_Member";
import SignInStaff from "./JWT/Signin";
import DashboardStaff from "./JWT/pages/Dashboard";
import Vetmember from "./modules/Vetmember";
import VetNurse from "./modules/VetNures";
import UserSignin from "./UserSign/UserSignin";
import CheckDataVet from "./pages/User/CheckData";
import Refunds from "./pages/Refunds";
import Contact from "./pages/Contact";
import FormCreateCheckIn from "./JWT/pages/FromCreateCheckIn";
import FromCheckIn from "./pages/FormCheckIn";
import GenarateQrCode from "./pages/User/GenarateQrCode";
import RegisterName from "./JWT/pages/RegisterName";
import ListNameRegi from "./JWT/pages/ListNameRegi";
import ListNameCheckIn from "./JWT/pages/ListNameCheckIn";
import Sponsor from "./JWT/pages/Sponsor";
import ListSponsor from "./JWT/pages/ListProjectSponsor";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/registermember",
    element: <RegisterMember />,
  },
  {
    path: "/signinstaff",
    element: <SignInStaff />,
  },
  {
    path: "/register",
  },
  {
    path: "/dashboard/staff",
    element: <DashboardStaff />,
  },
  {
    path: "/vet",
    element: <Vetmember />,
  },
  {
    path: "/vet/nurse",
    element: <VetNurse />,
  },
  {
    path: "/vet/tech",
    element: <VetNurse />,
  },
  {
    path: "/vet/scientist",
    element: <VetNurse />,
  },
  {
    path: "/vet/signin",
    element: <UserSignin />,
  },
  {
    path: "/check/Data",
    element: <CheckDataVet />,
  },
  {
    path: "/refunds",
    element: <Refunds />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/fromcreate/check/in",
    element: <FormCreateCheckIn />,
  },
  {
    path: "/from/check/in",
    element: <FromCheckIn />,
  },
  {
    path: "/genqrcode",
    element: <GenarateQrCode />,
  },
  {
    path: "/fromcreate/list/regi/name",
    element: <RegisterName />,
  },
  {
    path: "/fromcreate/list/name/regi",
    element: <ListNameRegi />,
  },
  {
    path: "/fromcreate/list/name/check/in",
    element: <ListNameCheckIn />,
  },
  {
    path: "/admin/sponsor",
    element: <Sponsor />,
  },
  {
    path: "/admin/listsponsor",
    element: <ListSponsor />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
