import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../css/style.css";
import logo from "./../assets/images/Postgraduate.svg";
import { publish, about } from "../inc/DataDetail";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config";
import Modal from "./Modal";
import { foodType } from "../inc/DataDetail";

function Navbar() {
  const navigrate = useNavigate();
  const [info, setInfo] = useState({});
  const [token, setToken] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    if (localStorage.getItem("token_pgt")) {
      AuthorizationInfo();
      fetchToken();
      localStorage.removeItem("token_pgt_staff");
    }
  }, []);

  const fetchToken = async () => {
    const token = await localStorage.getItem("token_pgt");
    setToken(token);
  };

  const AuthorizationInfo = async () => {
    try {
      const info = await axios.get(
        config.apiPath + config.urlPathUser + "/authorization/info",
        config.headers()
      );
      if (info.data.success === true) {
        setInfo(info.data.payload);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Authorization error",
      });
      setInfo({});
      navigrate("/vet/signin");
      window.location.reload();
    }
  };

  const handleSignout = async () => {
    try {
      localStorage.removeItem("token_pgt");
      localStorage.removeItem("codeId");
      window.location.reload();
      setInfo({});
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Authorization error",
      });
      setInfo({});
      navigrate("/vet/signin");
      window.location.reload();
    }
  };

  const handleEditData = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathUser + "/user/data/" + info.sub,
        config.headers()
      );
      if (res.data.success === true) {
        setData(res.data.result);
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "ติดตต่อเจ้าหน้าที่",
          text: e.code + " " + e.response.data.statusCode,
        });
      }
    }
  };

  const handleSaveEdit = async (event) => {
    try {
      event.preventDefault();

      const payload = {
        id: info.sub,
        data: data,
      };
      const res = await axios.put(
        config.apiPath + config.urlPathUser + "/update/new/data/user",
        payload,
        config.headers()
      );

      if (res.data.success === true) {
        Swal.fire({
          icon: "success",
          title: "แก้ไขข้อมูลสำเร็จ",
        });
        document.getElementById("btnModalClose").click();
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "ติดตต่อเจ้าหน้าที่",
          text: e.code + " " + e.response.data.statusCode,
        });
      }
    }
  };

  const handleClenLocalStoray = () => {
    localStorage.removeItem("token_pgt_staff");
  };

  return (
    <>
      <div className="container py-3">
        <div className="d-flex align-items-center ">
          <img src={logo} className="img-logo" alt="" />
          <div className="d-block ms-2">
            <span className="h1 text-muted">Postgraduate Education Center</span>
            <br />
            <span className="h6 ms-1 text-muted">
              Faculty of Veterinary Medicine Chiang Mai University
            </span>
          </div>
        </div>
      </div>
      <div className="border-top-navbar">
        <div className="container d-flex justify-content-between">
          <ul className="header-navbar">
            <li className="header-nav-item active">
              <Link
                to={"https://www.facebook.com/profile.php?id=61558866538285"}
                target="_blank"
                className="text-white"
              >
                Facebook Page
              </Link>
            </li>
          </ul>
          {token ? (
            <ul className="header-navbar">
              <Link className="header-nav-item active">
                {info.fnameTh + " " + info.lnameTh} ({info.codeId})
              </Link>

              <Link onClick={handleSignout} className="header-nav-item">
                <i className="fa-solid fa-right-from-bracket"></i> ออกระบบ
              </Link>
            </ul>
          ) : (
            <ul className="header-navbar">
              <Link to="/registermember" className="header-nav-item">
                สมัครสมาชิก
              </Link>
              <Link
                to="/vet/signin"
                onClick={handleClenLocalStoray}
                className="header-nav-item"
              >
                เข้าสู่ระบบ
              </Link>
            </ul>
          )}
        </div>
      </div>

      {token ? (
        <>
          {" "}
          <ul className="submenu-bar container">
            <li className="hover-dropdown">
              <Link to={"/"} className="nav-link">
                หน้าแรก
              </Link>
            </li>

            <li className="hover-dropdown">
              <Link to={"/check/Data"} className="nav-link">
                ตรวจสอบข้อมูล
              </Link>
            </li>
            <li className="hover-dropdown">
              <Link
                onClick={handleEditData}
                data-bs-toggle="modal"
                data-bs-target="#modalEditData"
                className="nav-link"
              >
                แก้ไขข้อมูล
              </Link>
            </li>
          </ul>
        </>
      ) : (
        <>
          {" "}
          <ul className="submenu-bar container">
            <li className="hover-dropdown">
              <Link to={"/"} className="nav-link">
                หน้าแรก
              </Link>
            </li>
            <li className="hover-dropdown">
              <Link to={"/contact"} className="nav-link">
                ติดต่อเรา
              </Link>
            </li>
          </ul>
        </>
      )}

      <nav
        className="navbar navbar-expand-lg pt-0 pb-0 mt-1"
        style={{ backgroundColor: "aliceblue" }}
      >
        <div className="container " style={{ alignItems: "flex-start" }}>
          <a className="navbar-brand mx-0 p-0" href="#"></a>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Link className="nav-link p-0">
                <small className="d-flex">
                  <div className="py-1"></div>
                </small>
              </Link>
            </ul>
          </div>
        </div>
      </nav>

      <Modal id="modalEditData" title={"แก้ไขข้อมูลเลขสมาชิก: " + info.codeId}>
        {data && data.admp ? (
          <>
            {" "}
            <form className="" onSubmit={handleSaveEdit}>
              <label htmlFor="admp" className="form-label">
                ตำแหน่งวิชาการ
              </label>
              <input
                type="text"
                value={data.admp}
                name="admp"
                className="form-control mb-2"
                placeholder="ศ. ,รศ. ,ผศ."
                onChange={(e) => setData({ ...data, admp: e.target.value })}
              />
              <label htmlFor="cecode" className="form-lable">
                เลขที่ใบอนุญาต <span className="text-danger">*</span>
              </label>
              <input
                value={data.cecode}
                type="text"
                name="cecode"
                className="form-control mb-2"
                placeholder="01-12345/2566"
                required
                onChange={(e) => setData({ ...data, cecode: e.target.value })}
              />
              <label htmlFor="certName" className="form-lable">
              ชื่อสำหรับออกใบ certificate (ชื่อ - นามสกุล ภาษาอังกฤษ)
              </label>
              <input
                value={data.certName}
                type="text"
                name="certName"
                className="form-control mb-2"
                onChange={(e) => setData({ ...data, certName: e.target.value })}
              />
              <label htmlFor="foodtype" className="form-lable">
                ประเภทการกิน <span className="text-danger">*</span>
              </label>
              <select
                name="foodtype"
                className="form-select"
                onChange={(e) => setData({ ...data, foodtype: e.target.value })}
                required
              >
                <option value={data.foodtype}>{data.foodtype}</option>
                {foodType.length > 0
                  ? foodType.map((item) => (
                      <option value={item.food}>{item.food}</option>
                    ))
                  : null}
              </select>
              <label htmlFor="line" className="form-lable">
                Line ID{" "}
              </label>
              <input
                value={data.lineId}
                type="text"
                name="line"
                className="form-control mb-2"
                onChange={(e) => setData({ ...data, lineId: e.target.value })}
              />
              <label htmlFor="worklocaltion" className="form-lable">
                ชื่อสถานประกอบการที่ทำงาน <span className="text-danger">*</span>
              </label>
              <input
                value={data.worklocaltion}
                type="text"
                name="worklocaltion"
                className="form-control mb-3"
                required
                onChange={(e) =>
                  setData({ ...data, worklocaltion: e.target.value })
                }
              />
              <button type="submit" className="btn btn-primary">
                บันทึกแก้ไขข้อมูล
              </button>
            </form>
          </>
        ) : (
          <>
            {" "}
            <form className="" onSubmit={handleSaveEdit}>
              <label htmlFor="cecode" className="form-lable">
                เลขที่ใบอนุญาต <span className="text-danger">*</span>
              </label>
              <input
                value={data.cecode}
                type="text"
                name="cecode"
                className="form-control mb-2"
                placeholder="01-12345/2566"
                required
                onChange={(e) => setData({ ...data, cecode: e.target.value })}
              />
              <label htmlFor="certName" className="form-lable">
              ชื่อสำหรับออกใบ certificate (ชื่อ - นามสกุล ภาษาอังกฤษ)
              </label>
              <input
                value={data.certName}
                type="text"
                name="certName"
                className="form-control mb-2"
                onChange={(e) => setData({ ...data, certName: e.target.value })}
              />
              <label htmlFor="foodtype" className="form-lable">
                ประเภทการกิน <span className="text-danger">*</span>
              </label>
              <select
                name="foodtype"
                className="form-select"
                onChange={(e) => setData({ ...data, foodtype: e.target.value })}
                required
              >
                <option key={'default'} value={data.foodtype}>{data.foodtype}</option>
                {foodType.length > 0
                  ? foodType.map((item) => (
                      <option key={item.id} value={item.food}>
                        {item.food}
                      </option>
                    ))
                  : null}
              </select>
              <label htmlFor="line" className="form-lable">
                Line ID{" "}
              </label>
              <input
                value={data.lineId}
                type="text"
                name="line"
                className="form-control mb-2"
                onChange={(e) => setData({ ...data, lineId: e.target.value })}
              />
              <label htmlFor="worklocaltion" className="form-lable">
                ชื่อสถานประกอบการที่ทำงาน <span className="text-danger">*</span>
              </label>
              <input
                value={data.worklocaltion}
                type="text"
                name="worklocaltion"
                className="form-control mb-3"
                required
                onChange={(e) =>
                  setData({ ...data, worklocaltion: e.target.value })
                }
              />
              <button type="submit" className="btn btn-primary">
                บันทึกแก้ไขข้อมูล
              </button>
            </form>
          </>
        )}
      </Modal>
    </>
  );
}
export default Navbar;
