import TemplateAdmin from "../component/TemplateAdmin";
import DataTable from "react-data-table-component";
import { rolesadmin } from "../../inc/DataDetail";
import { useEffect, useState, useMemo } from "react";
import { Swalerror } from "../modules/SwalError";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as dayJs from "dayjs";
import { Link } from "react-router-dom";
import MyModal from "../component/Mymodal";

function RegisterName() {
  const [dataTable, setDataTable] = useState([]);
  const [role, setRole] = useState("0");
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [dataModal, setDataModal] = useState({});

  useEffect(() => {
    fetchDataUser(role);
  }, [role]);

  const fetchDataUser = async () => {
    try {
      const base64Role = btoa("heander " + role);
      const res = await axios.get(
        config.apiPath + config.urlPathUser + "/list/user/" + base64Role,
        config.headers_staff()
      );
      if (res.data.success === true) {
        setDataTable(res.data.result);
      } else if (res.data.respCode === 209) {
        Swal.fire({
          icon: "info",
          title: "ไม่มีข้อมูล",
        });
      }
    } catch (e) {
      Swalerror(e, navigate);
    }
  };

  const columns = [
    {
      name: "รหัสสมาชิก",
      selector: (row) => row.codeId,
      width: "150px",
    },
    {
      name: "ชื่อนามสกุล",
      selector: (row) => `${row.prefix} ${row.fnameTh} ${row.lnameTh}`,
      width: "250px",
    },
    {
      name: "CE",
      selector: (row) => `${row.cecode}`,
      width: "200px",
    },
    {
      name: "Email",
      cell: (row) => {
        return (
          <>
            <Link to={`mailto:${row.email}`}>{row.email}</Link>
          </>
        );
      },
      width: "300px",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Data",
      cell: (row) => {
        return (
          <>
            <button
              onClick={() => setDataModal(row)}
              data-bs-toggle="modal"
              data-bs-target="#myModalSee"
              className="btn btn-primary"
            >
              See
            </button>
          </>
        );
      },
    },
  ];

  const data = dataTable
    ? dataTable.filter((item) =>
        Object.values(item).some((val) => {
          return (
            (typeof val === "string" &&
              val.toLowerCase().includes(searchText.toLowerCase())) ||
            (typeof val === "number" && val === parseInt(searchText))
          );
        })
      )
    : [];

  const textTitle = (
    <div className="d-flex justify-content-between mt-3">
      <div>ตารางข้อมูล</div>
    </div>
  );
  const downloadCSV = (data) => {
    const csvData = data.map((row) => ({
      numberId: row.codeId,
      Name: `${row.prefix} ${row.fnameTh} ${row.lnameTh}`,
      Code: row.idCard,
      CE: row.cecode,
      Role: row.role,
      Email: row.email,
      Phone: row.phone,
      Workshoplocation: row.worklocaltion ? row.worklocaltion : "",
      Province: row.workaddress ? row.workaddress : "",
      CertName: row.certName,
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(","), // แถวหัวข้อ
      ...csvData.map((row) => Object.values(row).join(",")), // แถวข้อมูล
    ].join("\n");

    // เพิ่ม BOM เพื่อให้แน่ใจว่าไฟล์จะถูกอ่านเป็น UTF-8
    const bom = "\uFEFF";
    const blob = new Blob([bom + csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${dayJs(new Date()).format(config.dateFormat)}.csv`;
    link.click();
  };

  const actionsMemo = useMemo(
    () => (
      <button className="btn btn-secondary" onClick={() => downloadCSV(data)}>
        {" "}
        <i className="fa fa-file"></i> Export
      </button>
    ),
    [data]
  );

  return (
    <>
      <TemplateAdmin>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="border-bottom">รายชื่อสมาชิกทั้งหมด</div>

              <div className="card card-admin-all-role">
                <select
                  className="form-select"
                  onChange={(e) => setRole(e.target.value)}
                >
                  {rolesadmin.map((item, index) => (
                    <option key={index} value={item.type}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="search" className="input-group-text">
                <i className="fa fa-search fa-1x me-2"></i>
              </label>
              <input
                type="text"
                name="search"
                className="form-control"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
                <DataTable
                  columns={columns}
                  data={data}
                  title={textTitle}
                  actions={actionsMemo}
                  pagination
                  paginationPerPage={10}
                />
              </div>
            </div>
          </div>
        </div>
      </TemplateAdmin>
      <MyModal id="myModalSee" title={"ข้อมูลเพิ่มเติม"}>
        <div className="row g-2">
          <div className="input-group">
            <span className="input-group-text">Line ID</span>
            <input
              type="text"
              className="form-control"
              value={dataModal.lineId !== null ? dataModal.lineId : "-"}
              readOnly
            />
          </div>
          <div className="input-group">
            <span className="input-group-text">Cert name</span>
            <input
              type="text"
              className="form-control"
              value={dataModal.certName !== null ? dataModal.certName : "-"}
              readOnly
            />
          </div>
          <div className="input-group">
            <span className="input-group-text">PDPA</span>
            <input
              type="text"
              className="form-control"
              value={dataModal.pdpa !== null ? dataModal.pdpa : "-"}
              readOnly
            />
          </div>
        </div>
      </MyModal>
    </>
  );
}
export default RegisterName;
