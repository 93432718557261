import { useState } from "react"

export const useCurrentYear = () => {
    const [currentYear, setCurrentYear] = useState(()=> {
        let myDate = new Date();
        return myDate.getFullYear();
    })

    return currentYear;
}

export const useArrYear = () => {
    const [arrYear, setArrYear] = useState(()=> {
        let arr = [];
        let myDate = new Date();
        let currentYear = myDate.getFullYear();
        let beforeYear = currentYear - 50;
        for (let i = beforeYear; i <= currentYear; i++) {
            arr.push(i)
        } 
        return arr
    })
    return arrYear;
}
