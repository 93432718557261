export const prefix = [
  { name_th: "นาย(Mr.)" },
  { name_th: "นางสาว(Miss)" },
  { name_th: "นางสาว(Mrs.)" },
];

export const sexs = [{ sex: "Male" }, { sex: "Female" }];

export const foodType = [
  { food: "General" },
  { food: "Vegetarian" },
  { food: "Halal" },
];

export const publish = [
  { name: "สมัครสมาชิก", link: "#modalManual1" },
  { name: "สมัครลงทะเบียนโครงการ", link: "#modalManual2" },
  // { name: "สิทธิประโยชน์", link: "/" },
];

export const about = [
  { name: "Title1", link: "/" },
  { name: "Title2", link: "/" },
  { name: "Title3", link: "/" },
  { name: "Title4", link: "/" },
];

export const roles = [
  {
    name: "Vet",
    link: "/vet?role=Vet",
  },
  {
    name: "Vet nurse",
    link: "/vet/nurse?role=Vet nurse",
  },
  {
    name: "Vet tech",
    link: "/vet/tech?role=Vet tech",
  },
  {
    name: "Scientist",
    link: "/vet/scientist?role=Scientist",
  },
];

export const rolesadmin = [
  {
    name: "All",
    type: 0
  },
  {
    name: "Vet",
    type: 1
  },
  {
    name: "Vet nurse",
    type: 2
  },
  {
    name: "Vet tech",
    type: 3
  },
  {
    name: "Scientist",
    type: 4
  },
];
