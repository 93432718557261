import React, { useEffect } from "react";
import useFetchDataInfoStaff from "../modules/useFetchDataInfo";
import ContentSidebar from "./ContentSidebar";
import NavbarAdmin from "./NavbarAdmin";
import SidebarAdmin from "./SidebarAdmin";

const TemplateAdmin = (props) => {
  const { infoStaff, fetchDataInfoStaff } = useFetchDataInfoStaff();

  useEffect(() => {
    fetchDataInfoStaff();
  }, []);

  return (
    <div className="wrapper">
      <SidebarAdmin infoStaff={infoStaff} />
      <NavbarAdmin infoStaff={infoStaff} />
      <div className="content-wrapper pt-3">
        <section className="content">
          {React.cloneElement(props.children, { infoStaff })}
        </section>
      </div>
      <ContentSidebar />
    </div>
  );
};

export default TemplateAdmin;
