import Swal from "sweetalert2";

export const Swalerror = async (e, navigate) => {
  if (
    e.response?.data?.error === "Unauthorized" &&
    e.response?.data?.statusCode === 401
  ) {
    Swal.fire({
      icon: "error",
      title: "Verify expired verification",
      text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
      timer: 2000,
    }).then(() => {
      localStorage.clear();
      navigate("/signinstaff");
    });
  } else {
    if (e.response.data.statusCode === 404) {
      Swal.fire({
        icon: "info",
        title: "ไม่มีข้อมูล",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "ติดตต่อเจ้าหน้าที่",
        text: e.code + " " + e.response.data.statusCode,
      }).then(() => {
        navigate("/signinstaff");
      });
    }
  }
};
