// src/Scanner.js
import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const Scanner = forwardRef(({ onScan }, ref) => {
  const qrCodeScannerRef = useRef(null);
  const scannerInstanceRef = useRef(null);

  useEffect(() => {
    const html5QrCodeScanner = new Html5QrcodeScanner(
      qrCodeScannerRef.current.id,
      { fps: 10, qrbox: { width: 400, height: 300 }},
      false
    );

    scannerInstanceRef.current = html5QrCodeScanner;

    html5QrCodeScanner.render(
      (decodedText, decodedResult) => {
        onScan([{ rawValue: decodedText, decodedResult }]);
        html5QrCodeScanner.pause(); // หยุดการสแกนหลังจากเจอ QR code
      },
      (error) => {
        // console.warn(`QR Code no match: ${error}`);
        // html5QrCodeScanner.pause();
      }
    );

    // ทำความสะอาดเมื่อคอมโพเนนต์ถูก unmount
    return () => {
      html5QrCodeScanner.clear().catch((error) => {
        console.error('Failed to clear qrCodeScanner:', error);
      });
    };
  }, []);

  useImperativeHandle(ref, () => ({
    resumeScanning: () => {
      if (scannerInstanceRef.current) {
        scannerInstanceRef.current.resume();
      }
    }
  }));

  return <div id="qr-reader" ref={qrCodeScannerRef}></div>;
});

export default Scanner;
