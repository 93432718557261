const config = {
  apiPath: "https://pgtapi.vet.cmu.ac.th",
  urlPathStaff: "/api/pgt/staff",
  urlPathUser: "/api/pgt/user",
  urlPathProject: "/api/pgt/project",
  urlPathCheckIn: "/api/pgt/checkin",
  headers: () => {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token_pgt"),
      },
    };
  },
  headers_staff: () => {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token_pgt_staff"),
      },
    };
  },
  dateFormat: "DD/MM/YYYY",
  dateFormatTime: "DD/MM/YYYY HH:mm",
  inputFormatDate: "YYYY-MM-DD",
  inputFormatTime: "HH:mm",
  privateKey:
    "3d9719d833602af747a1e981570ca87b463b1a9a6647e5b987ab34ad76af90eb",
};
export default config;
