function Modal (props) {
    let modalSize = "modal-dialog";
    if (props.modalSize) {
        modalSize += " " + props.modalSize;
    }
    return (
      <>
        <div 
        className="modal" 
        id={props.id}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        >
            <div className={modalSize}>
                <div className="modal-content">
                    <div className=" modal-header">
                        <div className="h1 modal-title fs-5 fw-bold">
                            {props.icon} {props.title}
                        </div>
                        <button
                        id="btnModalClose"
                        type="button"
                        className="btn-close btnClose"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        >

                        </button>
                    </div>
                    <div className="modal-body">{props.children}</div>
                </div>
            </div>
        </div>
      </>
    );
}
export default Modal;