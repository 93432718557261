import axios from "axios";
import { forwardRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../config";

const SidebarAdmin = forwardRef(({ infoStaff }, ref) => {
  const [count, setCount] = useState({});
  const [countMember, setCountMember] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    countDoucment();
    fetchCount();
  }, []);

  const fetchCount = async () => {
    try {
      const count = await axios.get(
        config.apiPath + config.urlPathUser + "/member/count"
      );
      if (count.data.success === true) {
        setCountMember(count.data.results);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const waiting = btoa("heander" + " " + "waiting");
  const approved = btoa("heander" + " " + "approved");
  const fail = btoa("heander" + " " + "fail");

  const countDoucment = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/count",
        config.headers_staff()
      );
      if (res.data.success === true) {
        setCount(res.data.counts);
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          navigate("/signinstaff");
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "ติดตต่อ ภพ 053948114",
        });
      }
    }
  };

  const handleViedo = async () => {
    navigate("/dashboard/staff");
  };

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to={"/dashboard/staff"} className="brand-link">
          <i className="fa fa-user"></i>{" "}
          <span className="brand-text font-weight-light">
            {infoStaff &&
            infoStaff.agency &&
            infoStaff.agency.Pgt_Staff_Agency &&
            infoStaff.agency.Pgt_Staff_Agency.agency ? (
              <>{infoStaff.agency.Pgt_Staff_Agency.agency}</>
            ) : (
              <></>
            )}
          </span>
        </Link>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link onClick={handleViedo} className="nav-link">
                  <p>Dashboard</p>
                </Link>
              </li>
              <div className=" border-bottom"></div>

              {infoStaff &&
              infoStaff.agency &&
              infoStaff.agency.agencyId === 3 ? (
                <>
                  <li className="nav-header bg-primary">สร้างฟอร์มเช็คอิน</li>
                  <li className="nav-item">
                    <Link to={`/fromcreate/check/in`} className="nav-link">
                      ระบบเช็คอินหน้างาน
                    </Link>
                  </li>
                  <div className=" border-bottom"></div>
                  <li className="nav-header bg-primary">
                    สมาชิกทั้งหมด{" "}
                    <span className="text-primary bg-blue px-2 rounded-bottom-circle">
                      {countMember}
                    </span>{" "}
                    คน
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/fromcreate/list/regi/name"}
                      className="nav-link"
                    >
                      รายชื่อสมาชิกทั้งหมด
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to={"/fromcreate/list/name/regi"}
                      className="nav-link"
                    >
                      รายชื่อโครงการ
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to={"/fromcreate/list/name/check/in"}
                      className="nav-link"
                    >
                      รายผู้เข้าร่วมงาน
                    </Link>
                  </li>
                  <div className=" border-bottom"></div>
                  <li className="nav-header bg-primary">Sponsor </li>

                  <li className="nav-item">
                    <Link to={"/admin/sponsor"} className="nav-link">
                      เพิ่มชื่อเข้าโครงการ
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to={"/admin/listsponsor"} className="nav-link">
                      ตรวจสอบรายชื่อ
                    </Link>
                  </li>
                  <div className=" border-bottom"></div>
                </>
              ) : null}

              {infoStaff &&
              infoStaff.agency &&
              infoStaff.agency.agencyId === 4 ? (
                <>
                  {" "}
                  <li className="nav-header">หลักฐานสลิปเงินโอน</li>
                  <li className="nav-item">
                    <Link
                      to={`?agency=${waiting}`}
                      className="nav-link text-info"
                    >
                      <i className="nav-icon far fa-plus-square"></i>
                      <p>
                        รอการอนุมัติ
                        {count.waiting !== 0 ? (
                          <>
                            {" "}
                            <span className="badge badge-info right">
                              {count.waiting}
                            </span>
                          </>
                        ) : null}
                      </p>
                    </Link>
                  </li>
                  <li className=" nav-item">
                    <Link
                      to={`?agency=${approved}`}
                      className="nav-link text-success"
                    >
                      <i className="nav-icon far fa-plus-square"></i>
                      <p>
                        อนุมัติสำเร็จ
                        {count.approved !== 0 ? (
                          <>
                            <span className="badge badge-success right">
                              {count.approved}
                            </span>
                          </>
                        ) : null}
                      </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={`?agency=${fail}`}
                      className="nav-link text-danger"
                    >
                      <i className="nav-icon far fa-plus-square"></i>
                      <p>
                        ไม่ผ่านอนุมัติ
                        {count.fail !== 0 ? (
                          <>
                            <span className="badge badge-danger right">
                              {count.fail}
                            </span>
                          </>
                        ) : null}
                      </p>
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
});

export default SidebarAdmin;
