import Template from "../component/Template";

function Refunds() {
  return (
    <>
      <Template>
        <div className="header-title">
          <div className="header-text">
            ข้อกำหนดและเงื่อนไขการคืนเงิน (Terms and Conditions of Refunds)
          </div>
        </div>

        <div className="card mt-4">
            <div className="card-body">
                <div className="h4 ">นโยบายการคืนเงิน</div>
                <p className="text-muted">คณะสัตวแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่ ขอสงวนสิทธิ์ในการคืนเงินให้กับท่านหลังจากที่ได้ชำระเงินค่าลงทะเบียนมาแล้วทุกกรณี กรุณาอ่านรายละเอียดการชำระเงินอย่างละเอียดก่อนทำการชำระเงินค่าลงทะเบียน</p>
                <div className="h4 ">Refund Policy</div>
                <p className="text-muted">Faculty of Veterinary Medicine Chiang Mai University. We reserve the right to refund to you after the registration fee has been paid in any case. Please read the payment details carefully before paying the registration fee.</p>
            </div>
        </div>
      </Template>
    </>
  );
}

export default Refunds;
