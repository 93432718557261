import { useEffect, useState } from "react";
import TemplateAdmin from "../component/TemplateAdmin";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";

function FormCreateCheckIn() {
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const [titleId, setTitleId] = useState("");

  useEffect(() => {
    fetchDataProject();
  }, []);

  const fetchDataProject = async () => {
    try {
      const res = await axios.get(
        config.apiPath + config.urlPathProject + "/list/home"
      );
      if (res.data.success === true) {
        setProject(res.data.results);
      }
    } catch (e) {
      if (
        e.response?.data?.error === "Unauthorized" &&
        e.response?.data?.statusCode === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Verify expired verification",
          text: "ตรวจสอบการยืนยันตัวตนหมดอายุ ล็อคอินใหม่อีกครั้ง",
          timer: 2000,
        }).then(() => {
          localStorage.clear();
          navigate("/signinstaff");
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "ติดตต่อ ภพ 053948114",
        });
      }
    }
  };

  return (
    <>
      <TemplateAdmin>
        <div className="card">
          <div className="card-body">
            <div className="border-bottom">สร้างฟอร์มเช็คอิน</div>
            <label htmlFor="" className="form-label mt-3">
              เลือกโครงการ
            </label>
            <select
              className="form-select"
              onChange={(e) => setTitleId(e.target.value)}
            >
              <option value="">เลือกโครงการที่ต้องการสร้าง</option>
              {project.length > 0
                ? project.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title.replace(
                        "The VMCMU Postgraduate Training Program:",
                        ""
                      )}
                    </option>
                  ))
                : null}
            </select>
            {titleId && (
              <>
                <div className="text-end">
                  <Link to={`/from/check/in?typeId=${btoa("heander " + titleId)}`} className="btn btn-primary mt-4">
                    Create form
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </TemplateAdmin>
    </>
  );
}

export default FormCreateCheckIn;
